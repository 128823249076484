const primaryColors = ['#5899da', '#19a979', '#E8743B', '#b71c1c', '#945ECF', '#FFD600', '#880E4F', '#1de9b6', '#6C8893', '#D500F9', '#283593', '#f48fb1', '#19a979', '#6C8893'];
const secondaryColors = ['#7986CB', '#004d40', '#6d4c41', '#ef5350', '#e1bee7', '#FFF176', '#FF4081', '#9E9D24', '#90a4ae', '#ffb74d', '#00897b', '#AED581', '#673AB7', '#388E3C'];

const allLocationsArray = [
	{
		locId: '0001-1',
		name: 'Woodbury Common',
		label: 'Woodbury Common',
		abbreviation: 'Woodbury',
		client: 'Lindt',
		color: '#5899da',
		categories: ['Outlet Centers'],
		owners: { 'Simon Property Group': ['Simon Premium Outlets'] },
		sensorCoverage: 'Woodbury Common:',
		sensorLocation: 'Woodbury Common',
		// startDate: '2019-12-22'
		startDate: '2019-04-09', // ML data
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0001-20',
		name: 'Wrentham Village',
		qName: ['Wrentham Village (Wrentham Court)', 'Wrentham Village (Liberty Court)'],
		label: 'Wrentham Village',
		abbreviation: 'Wrentham',
		client: 'Lindt',
		color: '#19a979',
		categories: ['Outlet Centers'],
		owners: { 'Simon Property Group': ['Simon Premium Outlets'] },
		sensorCoverage: 'Wrentham Village:',
		sensorLocation: 'Wrentham Village',
		startDate: '2022-12-10',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		parentOf: ['Wrentham Village (Wrentham Court)', 'Wrentham Village (Liberty Court)'],
		notes: 'not actual location, used as parent container to group child locations and allow to be selected as 1 location.'
	},
	{
		locId: '0001-18',
		name: 'Wrentham Village (Wrentham Court)',
		qName: 'Wrentham Village (Wrentham Court)',
		label: 'Wrentham Village (Wrentham Court)',
		abbreviation: 'Wrentham (Wrentham Ct)',
		client: 'Lindt',
		color: '#004d40',
		categories: ['Outlet Centers'],
		owners: { 'Simon Property Group': ['Simon Premium Outlets'] },
		sensorCoverage: 'Wrentham Village (Wrentham Court):',
		sensorLocation: 'Wrentham Village (Wrentham Court)',
		startDate: '2022-12-10',
		endDate: '',
		compare: false,
		subLocation: true,
		childOf: ['Wrentham Village'],
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0001-19',
		name: 'Wrentham Village (Liberty Court)',
		qName: 'Wrentham Village (Liberty Court)',
		label: 'Wrentham Village (Liberty Court)',
		abbreviation: 'Wrentham (Liberty Ct)',
		client: 'Lindt',
		color: '#6d4c41',
		categories: ['Outlet Centers'],
		owners: { 'Simon Property Group': ['Simon Premium Outlets'] },
		sensorCoverage: 'Wrentham Village (Liberty Court):',
		sensorLocation: 'Wrentham Village (Liberty Court)',
		startDate: '2022-12-10',
		endDate: '',
		compare: false,
		subLocation: true,
		childOf: ['Wrentham Village'],
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0001-3',
		name: 'NYC - Empire',
		label: 'NYC - Empire',
		abbreviation: 'NYC - Emp',
		client: 'Lindt',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'Vornado Realty Trust': [] },
		sensorCoverage: 'New York City:Empire',
		sensorLocation: 'NYC - Empire',
		// startDate: '2019-12-22'
		startDate: '2018-12-22', // ML data
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0001-5',
		name: 'King of Prussia',
		label: 'King of Prussia',
		abbreviation: 'KoP',
		client: 'Lindt',
		color: '#945ecf',
		categories: ['Shopping Malls'],
		owners: { 'Simon Property Group': ['Simon Malls'] },
		sensorCoverage: 'King of Prussia:',
		sensorLocation: 'King of Prussia',
		// startDate: '2020-06-27'
		startDate: '2019-02-28', // ML data
		endDate: '',
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 10,
		hrEnd: 21
	},
	{
		locId: '0001-6',
		name: 'Boston - Boylston',
		label: 'Boston - Boylston',
		abbreviation: 'Boston',
		client: 'Lindt',
		color: '#ffd600',
		categories: ['Downtown'],
		owners: { 'Saunders Hotel Group': [] },
		sensorCoverage: 'Boston:Boylston Street',
		sensorLocation: 'Boston - Boylston',
		// startDate: '2020-03-13'
		startDate: '2018-01-16', // ML data
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0001-7',
		name: 'Merrimack',
		label: 'Merrimack',
		abbreviation: 'Merrimack',
		client: 'Lindt',
		color: '#880e4f',
		categories: ['Outlet Centers'],
		owners: { 'Simon Property Group': ['Simon Premium Outlets'] },
		sensorCoverage: 'Merrimack:',
		sensorLocation: 'Merrimack',
		// startDate: '2020-03-13'
		startDate: '2018-01-18', // ML data
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0001-8',
		name: 'Orlando International',
		label: 'Orlando International',
		abbreviation: 'Orlando',
		client: 'Lindt',
		color: '#1de9b6',
		categories: ['Outlet Centers'],
		owners: { 'Simon Property Group': ['Simon Premium Outlets'] },
		sensorCoverage: 'Orlando International:',
		sensorLocation: 'Orlando International',
		// startDate: '2020-03-11'
		startDate: '2018-01-19', // ML data
		endDate: '',
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 10,
		hrEnd: 22
	},
	{
		locId: '0001-9',
		name: 'Fashion Outlets of Chicago',
		label: 'Fashion Outlets of Chicago',
		abbreviation: 'Chicago',
		client: 'Lindt',
		color: '#6c8893',
		categories: ['Outlet Centers'],
		owners: { 'Macerich': [] },
		sensorCoverage: 'Fashion Outlets of Chicago:',
		sensorLocation: 'Fashion Outlets of Chicago',
		// startDate: '2020-03-08'
		startDate: '2018-01-25', // ML data
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0001-10',
		name: 'Mall of America',
		label: 'Mall of America',
		abbreviation: 'MoA',
		client: 'Lindt',
		color: '#d500f9',
		categories: ['Shopping Malls'],
		owners: { 'Triple Five Group': [] },
		sensorCoverage: 'Mall of America:',
		sensorLocation: 'Mall of America',
		// startDate: '2020-03-06'
		startDate: '2018-01-22', // ML data
		endDate: '',
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 10,
		hrEnd: 22
	},
	{
		locId: '0001-11',
		name: 'Pentagon City',
		label: 'Pentagon City',
		abbreviation: 'Pentagon',
		client: 'Lindt',
		color: '#283593',
		categories: ['Shopping Malls'],
		owners: { 'Simon Property Group': ['Simon Malls'] },
		sensorCoverage: 'Pentagon City:(passing traffic)',
		sensorLocation: 'Pentagon City',
		startDate: '2020-07-11',
		endDate: '',
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 10,
		hrEnd: 22
	},
	{
		locId: '0001-12',
		name: 'Pentagon City (entrance)',
		label: 'Pentagon City (entrance)',
		abbreviation: 'Pentagon (entrance)',
		client: 'Lindt',
		color: '#f48fb1',
		categories: ['Shopping Malls'],
		owners: { 'Simon Property Group': ['Simon Malls'] },
		sensorCoverage: 'Pentagon City:(entering traffic)',
		sensorLocation: 'Pentagon City (entrance)',
		startDate: '2020-07-11',
		endDate: '',
		compare: false,
		subLocation: true,
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 10,
		hrEnd: 22
	},
	{
		locId: '0001-21',
		name: 'The Mills at Jersey Gardens',
		label: 'The Mills at Jersey Gardens',
		abbreviation: 'The Mills at Jersey Gardens',
		client: 'Lindt',
		color: primaryColors[13],
		categories: ['Shopping Malls'],
		owners: { 'Simon Property Group': ['Simon Malls'] },
		sensorCoverage: '',
		sensorLocation: '',
		startDate: '2023-12-01',
		endDate: '2023-12-02',
		byTimeStart: '2019-01-01T16:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 11,
		hrEnd: 21
	},
	{
		locId: '0001-2',
		name: 'Wrentham Village (old)',
		qName: 'Wrentham Village',
		label: 'Wrentham Village (old)',
		abbreviation: 'Wrentham (old)',
		client: 'Lindt',
		color: '#7986CB',
		categories: ['Outlet Centers'],
		owners: { 'Simon Property Group': ['Simon Premium Outlets'] },
		sensorCoverage: 'Wrentham Village (old):',
		sensorLocation: 'Wrentham Village (old)',
		// startDate: '2019-12-22'
		startDate: '2018-01-19', // ML data
		endDate: '2022-12-07',
		compare: false,
		groupLabel: 'Archived',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0001-4',
		name: 'NYC - Rolex',
		label: 'NYC - Rolex',
		abbreviation: 'NYC - Rlx',
		client: 'Lindt',
		color: '#b71c1c',
		categories: ['Downtown'],
		owners: { 'Rolex': [] },
		sensorCoverage: 'New York City:Rolex',
		sensorLocation: 'NYC - Rolex',
		// startDate: '2020-03-16'
		startDate: '2018-01-16', // ML data
		endDate: '2021-12-28',
		compare: false,
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0001-13',
		name: '579 5th Ave',
		label: '579 5th Ave',
		abbreviation: '579 5th Ave',
		client: 'Lindt',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Stawski Partners': [] },
		sensorCoverage: '579 5th Ave:',
		sensorLocation: '579 5th Ave',
		startDate: '2022-10-28',
		endDate: '2022-10-30',
		compare: false,
		groupLabel: '5th Ave Flagship Study',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0001-14',
		qName: 'Laderach (537 5th Ave)',
		name: 'Läderach (537 5th Ave)',
		label: 'Läderach (537 5th Ave)',
		abbreviation: 'Läderach (537 5th)',
		client: 'Lindt',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'Läderach': [] },
		sensorCoverage: 'Läderach:537 5th Ave',
		sensorLocation: '537 5th Ave',
		startDate: '2022-10-28',
		endDate: '2022-10-30',
		compare: false,
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0001-15',
		qName: 'Laderach Entering',
		name: 'Läderach Entering',
		label: 'Läderach Entering',
		abbreviation: 'Läderach Entering',
		client: 'Lindt',
		color: '#b71c1c',
		categories: ['Downtown'],
		owners: { 'Läderach': [] },
		sensorCoverage: 'Läderach:Entering',
		sensorLocation: 'Läderach Entering',
		startDate: '2022-10-28',
		endDate: '2022-10-30',
		compare: false,
		subLocation: true,
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0001-16',
		qName: 'Laderach Window Browsing',
		name: 'Läderach Window Browsing',
		label: 'Läderach Window Browsing',
		abbreviation: 'Läderach Window Browsing',
		client: 'Lindt',
		color: '#945ECF',
		categories: ['Downtown'],
		owners: { 'Läderach': [] },
		sensorCoverage: 'Läderach:Window Browsing',
		sensorLocation: 'Läderach Window Browsing',
		startDate: '2022-10-28',
		endDate: '2022-10-30',
		compare: false,
		subLocation: true,
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0001-17',
		name: 'Microsoft Store (677 5th Ave)',
		label: 'Microsoft Store (677 5th Ave)',
		abbreviation: 'Microsoft (677 5th)',
		client: 'Lindt',
		color: '#E8743B',
		categories: ['Downtown'],
		owners: { 'Microsoft': [] },
		sensorCoverage: 'Microsoft Store:677 5th Ave',
		sensorLocation: '677 5th Ave',
		startDate: '2022-10-28',
		endDate: '2022-10-30',
		compare: false,
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0002-1',
		name: 'Park Pizza & Brewing',
		label: 'Park Pizza & Brewing',
		abbreviation: 'Park Pizza & Brewing',
		client: 'Tavistock',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Tavistock': [] },
		sensorCoverage: 'Park Pizza & Brewing:',
		sensorLocation: 'Park Pizza & Brewing',
		startDate: '2020-01-28',
		endDate: '2020-02-22',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0002-2',
		name: 'Bosphorous Turkish Cuisine',
		label: 'Bosphorous Turkish Cuisine',
		abbreviation: 'Bosphorous Turkish Cuisine',
		client: 'Tavistock',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'Tavistock': [] },
		sensorCoverage: 'Bosphorous Turkish Cuisine:',
		sensorLocation: 'Bosphorous Turkish Cuisine',
		startDate: '2020-01-28',
		endDate: '2020-02-22',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0002-3',
		name: 'Chroma Modern Bar',
		label: 'Chroma Modern Bar',
		abbreviation: 'Chroma Modern Bar',
		client: 'Tavistock',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'Tavistock': [] },
		sensorCoverage: 'Chroma Modern Bar:',
		sensorLocation: 'Chroma Modern Bar',
		startDate: '2020-01-28',
		endDate: '2020-02-22',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0003-1',
		name: 'NYSE Intersection',
		label: 'NYSE Intersection',
		abbreviation: 'NYSE',
		client: 'DowntownAlliance',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Downtown Alliance': [] },
		sensorCoverage: 'NYSE Intersection:',
		sensorLocation: '23 Wall Street',
		startDate: '2020-09-06',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0003-2',
		name: 'Charging Bull',
		label: 'Charging Bull',
		abbreviation: 'Charging Bull',
		client: 'DowntownAlliance',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'Downtown Alliance': [] },
		sensorCoverage: 'Charging Bull:',
		sensorLocation: 'ADNY Lampost',
		startDate: '2020-09-06',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T01:00:00.000Z', // 8pm
		hrStart: 7,
		hrEnd: 20
	},
	{
		locId: '0003-3',
		name: '40 Water St',
		label: '40 Water St',
		abbreviation: '40 Water',
		client: 'DowntownAlliance',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'Downtown Alliance': [] },
		sensorCoverage: 'Coenties Slip:btw Pearl and Water',
		sensorLocation: 'PerfectBrows Salon, 40 Water Street',
		startDate: '2021-05-22',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0003-4',
		name: '104 Fulton St',
		label: '104 Fulton St',
		abbreviation: '104 Fulton',
		client: 'DowntownAlliance',
		color: '#b71c1c',
		categories: ['Downtown'],
		owners: { 'Downtown Alliance': [] },
		sensorCoverage: 'Fulton Street:(southside) btw Dutch and William',
		sensorLocation: 'Poke Bowl, 104 Fulton Street',
		startDate: '2021-05-22',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0003-5',
		name: '14 Wall St',
		label: '14 Wall St',
		abbreviation: '14 Wall',
		client: 'DowntownAlliance',
		color: '#945ecf',
		categories: ['Downtown'],
		owners: { 'ROZA 14 Wall': [] },
		sensorCoverage: 'Wall Street:btw Broadway and Nassau',
		sensorLocation: '14 Wall Street',
		startDate: '2021-05-22',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0003-6',
		name: '54 Stone St',
		label: '54 Stone St',
		abbreviation: '54 Stone',
		client: 'DowntownAlliance',
		color: '#ffd600',
		categories: ['Downtown'],
		owners: { 'HPH NYC': [] },
		sensorCoverage: 'Pearl Street:(westside) btw Hanover Sq and Coenties Slip',
		sensorLocation: 'Adrienne\'s Pizza, 54 Stone Street',
		startDate: '2021-05-25',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T04:00:00.000Z', // 11pm
		hrStart: 7,
		hrEnd: 23
	},
	{
		locId: '0003-7',
		name: '75 Wall St',
		label: '75 Wall St',
		abbreviation: '75 Wall',
		client: 'DowntownAlliance',
		color: '#880e4f',
		categories: ['Downtown'],
		owners: { 'Hakimian': [] },
		sensorCoverage: 'Wall Street:(southside) btw Pearl and Water',
		sensorLocation: '75 Wall Street',
		startDate: '2021-05-25',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0003-8',
		name: '55 Broad St',
		label: '55 Broad St',
		abbreviation: '55 Broad',
		client: 'DowntownAlliance',
		color: '#1de9b6',
		categories: ['Downtown'],
		owners: { 'Rudin Management': [] },
		sensorCoverage: 'Broad Street:(eastside) btw Beaver and Exchange Pl',
		sensorLocation: '55 Broad Street',
		startDate: '2021-05-29',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0003-9',
		name: '60 Broad St',
		label: '60 Broad St',
		abbreviation: '60 Broad',
		client: 'DowntownAlliance',
		color: '#6c8893',
		categories: ['Downtown'],
		owners: { 'Piedmont Office Management': [] },
		sensorCoverage: 'Broad Street:(westside) btw Beaver and Exchange Pl',
		sensorLocation: '60 Broad Street',
		startDate: '2021-06-26',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0003-10',
		name: '195 Broadway',
		label: '195 Broadway',
		abbreviation: '195 Broadway',
		client: 'DowntownAlliance',
		color: '#d500f9',
		categories: ['Downtown'],
		owners: { 'L&L Holding Company': [] },
		sensorCoverage: '195 Broadway:',
		sensorLocation: '195 Broadway',
		startDate: '2021-10-07',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0003-11',
		name: '26 Broadway',
		label: '26 Broadway',
		abbreviation: '26 Broadway',
		client: 'DowntownAlliance',
		color: '#283593',
		categories: ['Downtown'],
		owners: { 'L&L Holding Company': [] },
		sensorCoverage: '26 Broadway:',
		sensorLocation: '26 Broadway',
		startDate: '2021-09-18',
		endDate: '2022-07-07',
		compare: false,
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T01:00:00.000Z', // 8pm
		hrStart: 7,
		hrEnd: 20
	},
	{
		locId: '0004-1',
		name: '590 5th Ave',
		label: '590 5th Ave',
		abbreviation: '590 5th',
		client: 'ksr',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'SL Green': [] },
		sensorCoverage: '590 5th Ave:',
		sensorLocation: '590 5th Ave',
		startDate: '2021-05-12',
		endDate: '2021-08-13',
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 10,
		hrEnd: 22
	},
	{
		locId: '0005-3',
		name: '484 Broome St',
		label: '484 Broome St',
		abbreviation: '484 Broome',
		client: 'urbanumbrella',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '484 Broome St:',
		sensorLocation: '484 Broome St',
		startDate: '2021-05-22',
		endDate: '2022-07-03',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0005-5',
		name: '450 7th Ave',
		label: '450 7th Ave',
		abbreviation: '450 7th',
		client: 'urbanumbrella',
		color: '#945ecf',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '450 7th Ave:',
		sensorLocation: '450 7th Ave',
		startDate: '2021-05-22',
		endDate: '2022-07-03',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 7,
		hrEnd: 24
	},
	{
		locId: '0005-7',
		name: '1375 Broadway',
		label: '1375 Broadway',
		abbreviation: '1375 Broadway',
		client: 'urbanumbrella',
		color: '#880e4f',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '1375 Broadway:',
		sensorLocation: '1375 Broadway',
		startDate: '2021-05-29',
		endDate: '2022-07-12',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T04:00:00.000Z', // 11pm
		hrStart: 7,
		hrEnd: 23
	},
	{
		locId: '0005-8',
		name: '700 5th Ave',
		label: '700 5th Ave',
		abbreviation: '700 5th',
		client: 'urbanumbrella',
		color: '#1de9b6',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '700 5th Ave:',
		sensorLocation: '700 5th Ave',
		startDate: '2021-05-29',
		endDate: '2022-07-18',
		byTimeStart: '2019-01-01T13:00:00.000Z', // 8am
		byTimeEnd: '2019-01-02T04:00:00.000Z', // 11pm
		hrStart: 8,
		hrEnd: 23
	},
	{
		locId: '0005-11',
		name: '611 Broadway',
		label: '611 Broadway',
		abbreviation: '611 Broadway',
		client: 'urbanumbrella',
		color: '#283593',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '611 Broadway:',
		sensorLocation: '611 Broadway',
		startDate: '2021-07-16',
		endDate: '2022-07-12',
		byTimeStart: '2019-01-01T13:00:00.000Z', // 8am
		byTimeEnd: '2019-01-02T04:00:00.000Z', // 11pm
		hrStart: 8,
		hrEnd: 23
	},
	{
		locId: '0005-10',
		name: '5 Bryant Park',
		label: '5 Bryant Park',
		abbreviation: '5 Bryant Park',
		client: 'urbanumbrella',
		color: '#d500f9',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '5 Bryant Park:',
		sensorLocation: '5 Bryant Park',
		startDate: '2021-07-06',
		endDate: '2022-01-31',
		byTimeStart: '2019-01-01T13:00:00.000Z', // 8am
		byTimeEnd: '2019-01-02T04:00:00.000Z', // 11pm
		hrStart: 8,
		hrEnd: 23
	},
	{
		locId: '0005-4',
		name: '594 Broadway',
		label: '594 Broadway',
		abbreviation: '594 Broadway',
		client: 'urbanumbrella',
		color: '#b71c1c',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '594 Broadway:',
		sensorLocation: '594 Broadway',
		startDate: '2021-05-22',
		endDate: '2022-01-23',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T04:00:00.000Z', // 11pm
		hrStart: 7,
		hrEnd: 23
	},
	{
		locId: '0005-2',
		name: '103 MacDougal St',
		label: '103 MacDougal St',
		abbreviation: '103 MacDougal',
		client: 'urbanumbrella',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '103 MacDougal St:',
		sensorLocation: '103 MacDougal St',
		startDate: '2021-05-19',
		endDate: '2021-12-20',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0005-1',
		name: '145 Spring St',
		label: '145 Spring St',
		abbreviation: '145 Spring',
		client: 'urbanumbrella',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '145 Spring St:',
		sensorLocation: '145 Spring St',
		startDate: '2021-05-19',
		endDate: '2021-11-09',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0005-6',
		qName: '4 Park Ave',
		name: '4 Park Ave ', // whitespace intentionally added for name to be unique
		label: '4 Park Ave',
		abbreviation: '4 Park',
		client: 'urbanumbrella',
		color: '#ffd600',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '4 Park Ave:',
		sensorLocation: '4 Park Ave',
		startDate: '2021-05-22',
		endDate: '2021-10-31',
		byTimeStart: '2019-01-01T11:00:00.000Z', // 6am
		byTimeEnd: '2019-01-02T04:00:00.000Z', // 11pm
		hrStart: 6,
		hrEnd: 23
	},
	{
		locId: '0005-9',
		name: '760 Madison Ave',
		label: '760 Madison Ave',
		abbreviation: '760 Madison',
		client: 'urbanumbrella',
		color: '#6c8893',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '760 Madison Ave:',
		sensorLocation: '760 Madison Ave',
		startDate: '2021-06-12',
		endDate: '2021-10-24',
		byTimeStart: '2019-01-01T13:00:00.000Z', // 8am
		byTimeEnd: '2019-01-02T04:00:00.000Z', // 11pm
		hrStart: 8,
		hrEnd: 23
	},
	{
		locId: '0006-1',
		name: '145 Spring St (northside)',
		label: '145 Spring St (northside)',
		abbreviation: '145 Spring (N)',
		client: 'urbanumbrellademo',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '145 Spring St (northside):',
		sensorLocation: '145 Spring St (northside)',
		startDate: '2021-05-22',
		endDate: '2021-07-19',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0006-2',
		name: '145 Spring St (southside)',
		label: '145 Spring St (southside)',
		abbreviation: '145 Spring (S)',
		client: 'urbanumbrellademo',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'Urban Umbrella': [] },
		sensorCoverage: '145 Spring St (southside):',
		sensorLocation: '145 Spring St (southside)',
		startDate: '2021-05-19',
		endDate: '2021-07-19',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22
	},
	{
		locId: '0007-1',
		name: '591 Broadway',
		label: '591 Broadway',
		abbreviation: '591 Broadway',
		client: 'frontrunner',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Aurora Capital Associates': [] },
		sensorCoverage: '591 Broadway:',
		sensorLocation: '591 Broadway',
		startDate: '2021-10-07',
		endDate: '2021-11-24',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 7,
		hrEnd: 24
	},
	{
		locId: '0008-1',
		name: '461 7th Ave',
		label: '461 7th Ave',
		abbreviation: '461 7th Ave',
		client: 'delshah',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Delshah Capital': [] },
		sensorCoverage: '461 7th Ave:7th Ave and 35th St (SE corner)',
		sensorLocation: '461 7th Ave',
		startDate: '2021-11-01',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 7,
		hrEnd: 24
	},
	{
		locId: '0008-2',
		name: '761 7th Ave',
		label: '761 7th Ave',
		abbreviation: '761 7th Ave',
		client: 'delshah',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'Delshah Capital': [] },
		sensorCoverage: '761 7th Ave:7th Ave and 50th St (NE corner)',
		sensorLocation: '761 7th Ave',
		startDate: '2021-11-01',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 7,
		hrEnd: 24
	},
	{
		locId: '0009-1',
		name: 'Century City Cross Hallway',
		label: 'Century City Cross Hallway',
		abbreviation: 'Century City Cross Hallway',
		client: 'showfields',
		color: '#5899da',
		categories: ['Shopping Malls'],
		owners: { 'Unibail-Rodamco-Westfield': [] },
		sensorCoverage: 'Century City Cross Hallway:',
		sensorLocation: 'Century City Cross Hallway',
		startDate: '2021-11-05',
		endDate: '',
		dwells: false,
		dwell_threshold: 0,
		child: false,
		parent: true,
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 10,
		hrEnd: 21
	},
	{
		locId: '0009-2',
		name: 'Century City Hallway',
		label: 'Century City Hallway',
		abbreviation: 'Century City Hallway',
		client: 'showfields',
		color: '#19a979',
		categories: ['Shopping Malls'],
		owners: { 'Unibail-Rodamco-Westfield': [] },
		sensorCoverage: 'Century City Hallway:',
		sensorLocation: 'Century City Hallway',
		startDate: '2021-11-05',
		endDate: '',
		dwells: false,
		dwell_threshold: 0,
		child: false,
		parent: true,
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 10,
		hrEnd: 21
	},
	{
		locId: '0009-3',
		name: 'Century City Magic Box',
		label: 'Century City Magic Box',
		abbreviation: 'Century City Magic Box',
		client: 'showfields',
		color: '#e8743b',
		categories: ['Shopping Malls'],
		owners: { 'Showfields': [] },
		sensorCoverage: 'Century City Magic Box:',
		sensorLocation: 'Century City Magic Box',
		startDate: '2021-11-05',
		endDate: '',
		dwells: true,
		dwell_threshold: 10,
		child: true,
		parent: false,
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 10,
		hrEnd: 21
	},
	{
		locId: '0010-1',
		name: 'Oculus Passing Traffic',
		label: 'Oculus Passing Traffic',
		abbreviation: 'Oculus Passing Traffic',
		client: 'brickclik',
		color: '#5899da',
		categories: ['Shopping Malls'],
		owners: { 'Unibail-Rodamco-Westfield': [] },
		sensorCoverage: 'Oculus Passing Traffic:',
		sensorLocation: 'Oculus Passing Traffic',
		startDate: '2021-11-10',
		endDate: '',
		dwells: false,
		dwell_threshold: 0,
		child: false,
		parent: true,
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 10,
		hrEnd: 19
	},
	{
		locId: '0010-2',
		name: 'Oculus Front Room',
		label: 'Oculus Front Room',
		abbreviation: 'Oculus Front Room',
		client: 'brickclik',
		color: '#19a979',
		categories: ['Shopping Malls'],
		owners: { 'Unibail-Rodamco-Westfield': [] },
		sensorCoverage: 'Oculus Front Room:',
		sensorLocation: 'Oculus Front Room',
		startDate: '2021-11-10',
		endDate: '',
		dwells: false,
		dwell_threshold: 0,
		child: true,
		parent: true,
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 10,
		hrEnd: 19
	},
	{
		locId: '0010-3',
		name: 'Oculus Rotunda Room',
		label: 'Oculus Rotunda Room',
		abbreviation: 'Oculus Rotunda Room',
		client: 'brickclik',
		color: '#e8743b',
		categories: ['Shopping Malls'],
		owners: { 'Unibail-Rodamco-Westfield': [] },
		sensorCoverage: 'Oculus Rotunda Room:',
		sensorLocation: 'Oculus Rotunda Room',
		startDate: '2021-11-10',
		endDate: '',
		dwells: false,
		dwell_threshold: 0,
		child: true,
		parent: false,
		byTimeStart: '2019-01-01T15:00:00.000Z', // 10am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 10,
		hrEnd: 19
	},
	{
		locId: '0011-34',
		qName: [
			'6th Ave and 41st St Entrance', '42nd Street - Upper Terrace Entrance', '42nd Street - Upper Terrace NE Entrance', '42nd Street - Bathroom Entrance',
			'42nd Street - East Allee Entrance', '42nd Street - Plaza Entrance', '42nd Street - West Allee Entrance',
			'Heiskell Plaza Entrance', '40th Street - Upper Terrace Entrance', '40th Street - Maintenance Entrance',
			'40th Street - Plaza Entrance', '40th Street - West Allee Entrance'
		],
		name: 'Bryant Park Entering',
		label: 'Bryant Park Entering',
		abbreviation: 'Bryant Park Entering',
		client: 'bpc',
		color: primaryColors[0],
		// categories: ['Downtown'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-05-17',
		endDate: '',
		compare: false,
		expandLocation: true,
		defaultOpen: true,
		parentOf: [
			'6th Ave and 41st St Entrance', '42nd Street - Upper Terrace Entrance', '42nd Street - Upper Terrace NE Entrance', '42nd Street - Bathroom Entrance',
			'42nd Street - East Allee Entrance', '42nd Street - Plaza Entrance', '42nd Street - West Allee Entrance',
			'Heiskell Plaza Entrance', '40th Street - Upper Terrace Entrance', '40th Street - Maintenance Entrance',
			'40th Street - Plaza Entrance', '40th Street - West Allee Entrance'
		]
	},
	{
		locId: '0011-35',
		qName: [
			'6th Ave and 41st St Exit', '42nd Street - Upper Terrace Exit','42nd Street - Upper Terrace NE Exit', '42nd Street - Bathroom Exit',
			'42nd Street - East Allee Exit', '42nd Street - Plaza Exit', '42nd Street - West Allee Exit',
			'Heiskell Plaza Exit', '40th Street - Upper Terrace Exit', '40th Street - Maintenance Exit',
			'40th Street - Plaza Exit', '40th Street - West Allee Exit'
		],
		name: 'Bryant Park Exiting',
		label: 'Bryant Park Exiting',
		abbreviation: 'Bryant Park Exiting',
		client: 'bpc',
		color: primaryColors[1],
		// categories: ['Downtown'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-05-17',
		endDate: '',
		compare: false,
		expandLocation: true,
		defaultOpen: true,
		parentOf: [
			'6th Ave and 41st St Exit', '42nd Street - Upper Terrace Exit',  '42nd Street - Upper Terrace NE Exit','42nd Street - Bathroom Exit',
			'42nd Street - East Allee Exit', '42nd Street - Plaza Exit', '42nd Street - West Allee Exit',
			'Heiskell Plaza Exit', '40th Street - Upper Terrace Exit', '40th Street - Maintenance Exit',
			'40th Street - Plaza Exit', '40th Street - West Allee Exit'
		]
	},
	{
		locId: '0011-5',
		name: '6th Ave and 41st St Entrance',
		label: '6th Ave and 41st St Entrance',
		abbreviation: '6th Ave and 41st St Entrance',
		client: 'bpc',
		color: primaryColors[2],
		// categories: ['Downtown'],
		categories: ['Entering', '6th Ave Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-05-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		compare: true,
		childOf: ['Bryant Park Entering'],
	},
	{
		locId: '0011-6',
		name: '6th Ave and 41st St Exit',
		label: '6th Ave and 41st St Exit',
		abbreviation: '6th Ave and 41st St Exit',
		client: 'bpc',
		color: primaryColors[2],
		// categories: ['Downtown'],
		categories: ['Exiting', '6th Ave Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-05-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	
	{
		locId: '0011-8',
		name: '42nd Street - Upper Terrace NE Entrance',
		label: '42nd Street - Upper Terrace NE Entrance',
		abbreviation: '42nd St - U Terrace NE Entrance',
		client: 'bpc',
		color: primaryColors[3],
		// categories: ['Downtown'],
		categories: ['Entering', '42nd St Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Entering'],
	},
	
	{
		locId: '0011-10',
		name: '42nd Street - Upper Terrace Entrance',
		label: '42nd Street - Upper Terrace Entrance',
		abbreviation: '42nd St - U Terrace Entrance',
		client: 'bpc',
		color: primaryColors[4],
		// categories: ['Downtown'],
		categories: ['Entering', '42nd St Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Entering'],
	},
	{
		locId: '0011-11',
		name: '42nd Street - Upper Terrace Exit',
		label: '42nd Street - Upper Terrace Exit',
		abbreviation: '42nd St - U Terrace Exit',
		client: 'bpc',
		color: primaryColors[4],
		// categories: ['Downtown'],
		categories: ['Exiting', '42nd St Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	{
		locId: '0011-9',
		name: '42nd Street - Upper Terrace NE Exit',
		label: '42nd Street - Upper Terrace NE Exit',
		abbreviation: '42nd St - U Terrace NE Exit',
		client: 'bpc',
		color: primaryColors[3],
		// categories: ['Downtown'],
		categories: ['Exiting', '42nd St Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	{
		locId: '0011-12',
		name: '42nd Street - Bathroom Entrance',
		label: '42nd Street - Bathroom Entrance',
		abbreviation: '42nd St - Bathroom Entrance',
		client: 'bpc',
		color: primaryColors[5],
		// categories: ['Downtown'],
		categories: ['Entering', '42nd St Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Entering'],
	},
	{
		locId: '0011-13',
		name: '42nd Street - Bathroom Exit',
		label: '42nd Street - Bathroom Exit',
		abbreviation: '42nd St - Bathroom Exit',
		client: 'bpc',
		color: primaryColors[5],
		// categories: ['Downtown'],
		categories: ['Exiting', '42nd St Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	{
		locId: '0011-14',
		name: '42nd Street - East Allee Entrance',
		label: '42nd Street - East Allee Entrance',
		abbreviation: '42nd St - East Allee Entrance',
		client: 'bpc',
		color: primaryColors[6],
		// categories: ['Downtown'],
		categories: ['Entering', '42nd St Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Entering'],
	},
	{
		locId: '0011-15',
		name: '42nd Street - East Allee Exit',
		label: '42nd Street - East Allee Exit',
		abbreviation: '42nd St - East Allee Exit',
		client: 'bpc',
		color: primaryColors[6],
		// categories: ['Downtown'],
		categories: ['Exiting', '42nd St Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	{
		locId: '0011-16',
		name: 'Bathroom Count',
		label: 'Bathroom Count',
		abbreviation: 'Bathroom Count',
		client: 'bpc',
		color: secondaryColors[2],
		// categories: ['Downtown'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0011-7',
		name: '6th Ave and 41st St Passing',
		label: '6th Ave and 41st St Passing',
		abbreviation: '6th Ave and 41st St Passing',
		client: 'bpc',
		color: secondaryColors[9],
		// categories: ['Downtown'],
		categories: ['Passing'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-05-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0011-37',
		qName: '6th Ave at 40th Passing',
		name: '6th Ave at 42nd Passing',
		label: '6th Ave at 42nd Passing',
		abbreviation: '6th Ave at 42nd Passing',
		client: 'bpc',
		color: secondaryColors[1],
		// categories: ['Downtown'],
		categories: ['Passing'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0011-38',
		qName: '40th St at 6th Passing',
		name: '42nd St at 6th Passing',
		label: '42nd St at 6th Passing',
		abbreviation: '42nd St at 6th Passing',
		client: 'bpc',
		color: secondaryColors[7],
		// categories: ['Downtown'],
		categories: ['Passing'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0011-36',
		name: '42nd Street - Plaza Passing',
		label: '42nd Street - Plaza Passing',
		abbreviation: '42nd St - Plaza Passing',
		client: 'bpc',
		color: secondaryColors[6],
		// categories: ['Downtown'],
		categories: ['Passing'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0011-17',
		qName: '42nd Street - Bathroom Passing',
		name: '42nd Street - Terrace Passing',
		label: '42nd Street - Terrace Passing',
		abbreviation: '42nd St - Terrace Passing',
		client: 'bpc',
		color: secondaryColors[3],
		// categories: ['Downtown'],
		categories: ['Passing'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0011-18',
		name: '42nd Street - Plaza Entrance',
		label: '42nd Street - Plaza Entrance',
		abbreviation: '42nd St - Plaza Entrance',
		client: 'bpc',
		color: primaryColors[7],
		// categories: ['Downtown'],
		categories: ['Entering', '42nd St Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Entering'],
	},
	{
		locId: '0011-19',
		name: '42nd Street - Plaza Exit',
		label: '42nd Street - Plaza Exit',
		abbreviation: '42nd St - Plaza Exit',
		client: 'bpc',
		color: primaryColors[7],
		// categories: ['Downtown'],
		categories: ['Exiting', '42nd St Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	{
		locId: '0011-20',
		name: '42nd Street - West Allee Entrance',
		label: '42nd Street - West Allee Entrance',
		abbreviation: '42nd St - West Allee Entrance',
		client: 'bpc',
		color: primaryColors[8],
		// categories: ['Downtown'],
		categories: ['Entering', '42nd St Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Entering'],
	},
	{
		locId: '0011-21',
		name: '42nd Street - West Allee Exit',
		label: '42nd Street - West Allee Exit',
		abbreviation: '42nd St - West Allee Exit',
		client: 'bpc',
		color: primaryColors[8],
		// categories: ['Downtown'],
		categories: ['Exiting', '42nd St Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	{
		locId: '0011-22',
		name: 'Heiskell Plaza Entrance',
		label: 'Heiskell Plaza Entrance',
		abbreviation: 'Heiskell Plaza Entrance',
		client: 'bpc',
		color: primaryColors[9],
		// categories: ['Downtown'],
		categories: ['Entering', 'Heiskell Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Entering'],
	},
	{
		locId: '0011-23',
		name: 'Heiskell Plaza Exit',
		label: 'Heiskell Plaza Exit',
		abbreviation: 'Heiskell Plaza Exit',
		client: 'bpc',
		color: primaryColors[9],
		// categories: ['Downtown'],
		categories: ['Exiting', 'Heiskell Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	{
		locId: '0011-24',
		name: '40th Street - Upper Terrace Entrance',
		label: '40th Street - Upper Terrace Entrance',
		abbreviation: '40th St - Upper Terrace Entrance',
		client: 'bpc',
		color: primaryColors[10],
		// categories: ['Downtown'],
		categories: ['Entering', '40th St Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Entering'],
	},
	{
		locId: '0011-25',
		name: '40th Street - Upper Terrace Exit',
		label: '40th Street - Upper Terrace Exit',
		abbreviation: '40th St - Upper Terrace Exit',
		client: 'bpc',
		color: primaryColors[10],
		// categories: ['Downtown'],
		categories: ['Exiting', '40th St Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	{
		locId: '0011-26',
		name: '40th Street - Maintenance Entrance',
		label: '40th Street - Maintenance Entrance',
		abbreviation: '40th St - Maintenance Entrance',
		client: 'bpc',
		color: primaryColors[11],
		// categories: ['Downtown'],
		categories: ['Entering', '40th St Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Entering'],
	},
	{
		locId: '0011-27',
		name: '40th Street - Maintenance Exit',
		label: '40th Street - Maintenance Exit',
		abbreviation: '40th St - Maintenance Exit',
		client: 'bpc',
		color: primaryColors[11],
		// categories: ['Downtown'],
		categories: ['Exiting', '40th St Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	{
		locId: '0011-33',
		name: '40th Street - Plaza Passing',
		label: '40th Street - Plaza Passing',
		abbreviation: '40th St - Plaza Passing',
		client: 'bpc',
		color: secondaryColors[5],
		// categories: ['Downtown'],
		categories: ['Passing'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0011-28',
		name: '40th Street - Terrace Passing',
		label: '40th Street - Terrace Passing',
		abbreviation: '40th St - Terrace Passing',
		client: 'bpc',
		color: secondaryColors[4],
		// categories: ['Downtown'],
		categories: ['Passing'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0011-29',
		name: '40th Street - Plaza Entrance',
		label: '40th Street - Plaza Entrance',
		abbreviation: '40th St - Plaza Entrance',
		client: 'bpc',
		color: primaryColors[12],
		// categories: ['Downtown'],
		categories: ['Entering', '40th St Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Entering'],
	},
	{
		locId: '0011-30',
		name: '40th Street - Plaza Exit',
		label: '40th Street - Plaza Exit',
		abbreviation: '40th St - Plaza Exit',
		client: 'bpc',
		color: primaryColors[12],
		// categories: ['Downtown'],
		categories: ['Exiting', '40th St Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	{
		locId: '0011-31',
		name: '40th Street - West Allee Entrance',
		label: '40th Street - West Allee Entrance',
		abbreviation: '40th St - West Allee Entrance',
		client: 'bpc',
		color: secondaryColors[0],
		// categories: ['Downtown'],
		categories: ['Entering', '40th St Enter'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Entering'],
	},
	{
		locId: '0011-32',
		name: '40th Street - West Allee Exit',
		label: '40th Street - West Allee Exit',
		abbreviation: '40th St - West Allee Exit',
		client: 'bpc',
		color: secondaryColors[0],
		// categories: ['Downtown'],
		categories: ['Exiting', '40th St Exit'],
		owners: { 'Bryant Park Corporation': [] },
		startDate: '2023-07-17',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		childOf: ['Bryant Park Exiting'],
	},
	
	// {
	// 	name: 'Other Locations',
	// 	label: 'Other Locations',
	// 	abbreviation: 'Other Locations',
	// 	client: 'bpc',
	// 	compare: false,
	// 	archived: false,
	// 	expandLabel: true,
	// 	parentOf: ['34th St (N Sidewalk btw 5th & 6th)'],
	// },
	// {
	// 	locId: '0011-8',
	// 	qName: 'NYC - Empire',
	// 	name: '34th St (N Sidewalk btw 5th & 6th)',
	// 	label: '34th St (N Sidewalk btw 5th & 6th)',
	// 	abbreviation: '34th St (N Sidewalk btw 5th & 6th)',
	// 	client: 'bpc',
	// 	color: '#f48fb1',
	// 	categories: ['Downtown'],
	// 	owners: { 'Vornado Realty Trust': [] },
	// 	startDate: '2019-12-22',
	// 	endDate: '',
	// 	byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
	// 	byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
	// 	hrStart: 9,
	// 	hrEnd: 22,
	// 	childOf: ['Other Locations'],
	// },
	// {
	// 	locId: '0011-1',
	// 	name: 'W 40th Gate Entering',
	// 	label: 'W 40th Gate Entering',
	// 	abbreviation: 'W 40th Entering',
	// 	client: 'bpc',
	// 	color: '#5899da',
	// 	categories: ['Downtown'],
	// 	owners: { 'Bryant Park Corporation': [] },
	// 	sensorCoverage: 'W 40th Gate Entering:',
	// 	sensorLocation: 'W 40th Gate Entering',
	// 	startDate: '2022-06-06',
	// 	endDate: '2022-06-12',
	// 	byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
	// 	byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
	// 	hrStart: 0,
	// 	hrEnd: 24,
	// 	compare: false,
	// 	childOf: ['Other Locations'],
	// },
	// {
	// 	locId: '0011-2',
	// 	name: 'W 40th Gate Exiting',
	// 	label: 'W 40th Gate Exiting',
	// 	abbreviation: 'W 40th Exiting',
	// 	client: 'bpc',
	// 	color: '#19a979',
	// 	categories: ['Downtown'],
	// 	owners: { 'Bryant Park Corporation': [] },
	// 	sensorCoverage: 'W 40th Gate Exiting:',
	// 	sensorLocation: 'W 40th Gate Exiting',
	// 	startDate: '2022-06-06',
	// 	endDate: '2022-06-12',
	// 	byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
	// 	byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
	// 	hrStart: 0,
	// 	hrEnd: 24,
	// 	compare: false,
	// 	childOf: ['Other Locations'],
	// },
	// {
	// 	locId: '0011-3',
	// 	name: 'W 40th Gate Sidewalk',
	// 	label: 'W 40th Gate Sidewalk',
	// 	abbreviation: 'W 40th Sidewalk',
	// 	client: 'bpc',
	// 	color: '#e8743b',
	// 	categories: ['Downtown'],
	// 	owners: { 'Bryant Park Corporation': [] },
	// 	sensorCoverage: 'W 40th Gate Sidewalk:',
	// 	sensorLocation: 'W 40th Gate Sidewalk',
	// 	startDate: '2022-06-06',
	// 	endDate: '2022-06-12',
	// 	byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
	// 	byTimeEnd: '2019-01-02T05:00:00.000Z', // midnighta
	// 	hrStart: 0,
	// 	hrEnd: 24,
	// 	compare: false,
	// 	childOf: ['Other Locations'],
	// },
	// {
	// 	locId: '0011-4',
	// 	name: 'W 40th Gate Table Occupancy',
	// 	label: 'W 40th Gate Table Occupancy',
	// 	abbreviation: 'W 40th Table Occupancy',
	// 	client: 'bpc',
	// 	color: '#945ecf',
	// 	categories: ['Downtown'],
	// 	owners: { 'Bryant Park Corporation': [] },
	// 	sensorCoverage: 'W 40th Gate Table Occupancy:',
	// 	sensorLocation: 'W 40th Gate Table Occupancy',
	// 	startDate: '2022-06-06',
	// 	endDate: '2022-06-12',
	// 	byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
	// 	byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
	// 	hrStart: 0,
	// 	hrEnd: 24,
	// 	compare: false,
	// 	childOf: ['Other Locations'],
	// },
	{
		locId: '0012-1',
		qName: 'Massachusetts Ave Passing',
		name: 'Massachusetts Ave',
		label: 'Massachusetts Ave',
		abbreviation: 'Mass Ave',
		client: 'mcdonalds',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Boston Residential Group': [] },
		sensorCoverage: 'Massachusetts Ave:',
		sensorLocation: 'Massachusetts Ave',
		startDate: '2022-08-06', // '2022-07-21'
		endDate: '2022-11-15',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0012-2',
		qName: 'Edgerly Rd East Passing',
		name: 'McDonald\'s Sidewalk',
		label: 'McDonald\'s Sidewalk',
		abbreviation: 'McDonald\'s Sidewalk',
		client: 'mcdonalds',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'Boston Residential Group': [] },
		sensorCoverage: 'McDonald\'s Sidewalk:',
		sensorLocation: 'McDonald\'s Sidewalk',
		startDate: '2022-08-06', // '2022-07-21'
		endDate: '2022-11-15',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0012-3',
		qName: 'Edgerly Rd West Passing',
		name: 'Whole Foods Sidewalk',
		label: 'Whole Foods Sidewalk',
		abbreviation: 'Whole Foods Sidewalk',
		client: 'mcdonalds',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'Boston Residential Group': [] },
		sensorCoverage: 'Whole Foods Sidewalk:',
		sensorLocation: 'Whole Foods Sidewalk',
		startDate: '2022-08-06', // '2022-07-21'
		endDate: '2022-11-15',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0012-4',
		qName: 'Whole Foods Entering',
		name: 'Whole Foods Entering',
		label: 'Whole Foods Entering',
		abbreviation: 'Whole Foods Entering',
		client: 'mcdonalds',
		color: '#b71c1c',
		categories: ['Downtown'],
		owners: { 'Boston Residential Group': [] },
		sensorCoverage: 'Whole Foods Entering:',
		sensorLocation: 'Whole Foods Entering',
		startDate: '2022-08-06',
		endDate: '2022-11-15',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0012-5',
		qName: 'Whole Foods Exiting',
		name: 'Whole Foods Exiting',
		label: 'Whole Foods Exiting',
		abbreviation: 'Whole Foods Exiting',
		client: 'mcdonalds',
		color: '#945ecf',
		categories: ['Downtown'],
		owners: { 'Boston Residential Group': [] },
		sensorCoverage: 'Whole Foods Exiting:',
		sensorLocation: 'Whole Foods Exiting',
		startDate: '2022-08-06',
		endDate: '2022-11-15',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0012-6',
		qName: 'Edgerly Crossing',
		name: 'Edgerly Crossing',
		label: 'Edgerly Crossing',
		abbreviation: 'Edgerly Crossing',
		client: 'mcdonalds',
		color: '#FFD600',
		categories: ['Downtown'],
		owners: { 'Boston Residential Group': [] },
		sensorCoverage: 'Edgerly Crossing:',
		sensorLocation: 'Edgerly Crossing',
		startDate: '2022-08-06',
		endDate: '2022-11-15',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0012-7',
		qName: 'New Edgerly Crossing',
		name: 'New Edgerly Crossing',
		label: 'New Edgerly Crossing',
		abbreviation: 'New Edgerly Crossing',
		client: 'mcdonalds',
		color: '#880E4F',
		categories: ['Downtown'],
		owners: { 'Boston Residential Group': [] },
		sensorCoverage: 'New Edgerly Crossing:',
		sensorLocation: 'New Edgerly Crossing',
		startDate: '2022-08-06',
		endDate: '2022-11-15',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0012-8',
		qName: 'New Edgerly Passage Entering',
		name: 'Mass Ave - Edgerly Passage Entering',
		label: 'Mass Ave - Edgerly Passage Entering',
		abbreviation: 'Passage Entering',
		client: 'mcdonalds',
		color: '#1de9b6',
		categories: ['Downtown'],
		owners: { 'Boston Residential Group': [] },
		sensorCoverage: 'Mass Ave - Edgerly Passage Entering:',
		sensorLocation: 'Mass Ave - Edgerly Passage Entering',
		startDate: '2022-08-06', // '2022-07-21'
		endDate: '2022-11-15',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0012-9',
		qName: 'New Edgerly Passage Exiting',
		name: 'Mass Ave - Edgerly Passage Exiting',
		label: 'Mass Ave - Edgerly Passage Exiting',
		abbreviation: 'Passage Exiting',
		client: 'mcdonalds',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'Boston Residential Group': [] },
		sensorCoverage: 'Mass Ave - Edgerly Passage Exiting:',
		sensorLocation: 'Mass Ave - Edgerly Passage Exiting',
		startDate: '2022-08-06', // '2022-07-21'
		endDate: '2022-11-15',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0013-1',
		qName: '000us0002L000occ',
		name: 'USQ South Plaza Occupancy',
		label: 'USQ South Plaza Occupancy',
		abbreviation: 'USQ S Plaza Occupancy',
		client: 'usp',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ South Plaza Occupancy:',
		sensorLocation: 'USQ South Plaza Occupancy',
		startDate: '2023-01-15', // '2022-07-21'
		endDate: '',
		byTimeStart: '2019-01-01T11:00:00.000Z', // 6am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
		compare: false,
		occupancy: true,
	},
	{
		locId: '0013-2',
		qName: '000us0002L000een',
		name: 'East Entering',
		label: 'USQ S Plaza East Entering',
		abbreviation: 'USQ S Plaza East Entering',
		client: 'usp',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ S Plaza East Entering:',
		sensorLocation: 'USQ S Plaza East Entering',
		startDate: '2023-01-15', // '2022-07-21'
		endDate: '',
		byTimeStart: '2019-01-01T11:00:00.000Z', // 6am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0013-3',
		qName: '000us0002L000eex',
		name: 'East Exiting',
		label: 'USQ S Plaza East Exiting',
		abbreviation: 'USQ S Plaza East Exiting',
		client: 'usp',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ S Plaza East Exiting:',
		sensorLocation: 'USQ S Plaza East Exiting',
		startDate: '2023-01-15', // '2022-07-21'
		endDate: '',
		byTimeStart: '2019-01-01T11:00:00.000Z', // 6am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0013-4',
		qName: '000us0002L000wen',
		name: 'West Entering',
		label: 'USQ S Plaza West Entering',
		abbreviation: 'USQ S Plaza West Entering',
		client: 'usp',
		color: '#b71c1c',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ S Plaza West Entering:',
		sensorLocation: 'USQ S Plaza West Entering',
		startDate: '2023-01-15', // '2022-07-21'
		endDate: '',
		byTimeStart: '2019-01-01T11:00:00.000Z', // 6am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0013-5',
		qName: '000us0002L000wex',
		name: 'West Exiting',
		label: 'USQ S Plaza West Exiting',
		abbreviation: 'USQ S Plaza West Exiting',
		client: 'usp',
		color: '#945ECF',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ S Plaza West Exiting:',
		sensorLocation: 'USQ S Plaza West Exiting',
		startDate: '2023-01-15', // '2022-07-21'
		endDate: '',
		byTimeStart: '2019-01-01T11:00:00.000Z', // 6am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0013-6',
		qName: '000us0002L00senx',
		name: 'South Entering (Xwalk)',
		label: 'USQ S Plaza South Entering (Xwalk)',
		abbreviation: 'USQ S Plaza South Entering (Xwalk)',
		client: 'usp',
		color: '#FFD600',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ S Plaza South Entering (Xwalk):',
		sensorLocation: 'USQ S Plaza South Entering (Xwalk)',
		startDate: '2023-01-15', // '2022-07-21'
		endDate: '',
		byTimeStart: '2019-01-01T11:00:00.000Z', // 6am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0013-7',
		qName: '000us0002L00sexx',
		name: 'South Exiting (Xwalk)',
		label: 'USQ S Plaza South Exiting (Xwalk)',
		abbreviation: 'USQ S Plaza South Exiting (Xwalk)',
		client: 'usp',
		color: '#880E4F',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ S Plaza South Exiting (Xwalk):',
		sensorLocation: 'USQ S Plaza South Exiting (Xwalk)',
		startDate: '2023-01-15', // '2022-07-21'
		endDate: '',
		byTimeStart: '2019-01-01T11:00:00.000Z', // 6am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0013-8',
		qName: '000us0002L000sen',
		name: 'South Entering (street)',
		label: 'USQ S Plaza South Entering (street)',
		abbreviation: 'USQ S Plaza South Entering (street)',
		client: 'usp',
		color: '#1de9b6',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ S Plaza South Entering (street):',
		sensorLocation: 'USQ S Plaza South Entering (street)',
		startDate: '2023-01-15', // '2022-07-21'
		endDate: '',
		byTimeStart: '2019-01-01T11:00:00.000Z', // 6am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0013-9',
		qName: '000us0002L000sex',
		name: 'South Exiting (street)',
		label: 'USQ S Plaza South Exiting (street)',
		abbreviation: 'USQ S Plaza South Exiting (street)',
		client: 'usp',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ S Plaza South Exiting (street):',
		sensorLocation: 'USQ S Plaza South Exiting (street)',
		startDate: '2023-01-15', // '2022-07-21'
		endDate: '',
		byTimeStart: '2019-01-01T11:00:00.000Z', // 6am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 6,
		hrEnd: 24,
	},
	{
		locId: '0013-10',
		qName: '200 Park Ave South (both sidewalks)',
		name: '200 Park Ave South (both sidewalks)',
		label: '200 Park Ave South (both sidewalks)',
		abbreviation: '200 PAS (both sidewalks)',
		client: 'usp',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'ABSRE': [] },
		sensorCoverage: '200 Park Ave South:',
		sensorLocation: 'both sidewalks',
		startDate: '2022-09-09', // '2022-07-21'
		endDate: '2022-12-05',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		subLocation: true,
		compare: false,
	},
	{
		locId: '0013-11',
		qName: '200 Park Ave South (Park Ave sidewalk)',
		name: '200 Park Ave South (Park Ave sidewalk)',
		label: '200 Park Ave South (Park Ave sidewalk)',
		abbreviation: '200 PAS (Park Ave sidewalk)',
		client: 'usp',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'ABSRE': [] },
		sensorCoverage: '200 Park Ave South:',
		sensorLocation: 'Park Ave sidewalk',
		startDate: '2022-09-09', // '2022-07-21'
		endDate: '2022-12-05',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
	},
	{
		locId: '0013-12',
		qName: '200 Park Ave South (17th St sidewalk)',
		name: '200 Park Ave South (17th St sidewalk)',
		label: '200 Park Ave South (17th St sidewalk)',
		abbreviation: '200 PAS (17th St sidewalk)',
		client: 'usp',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'ABSRE': [] },
		sensorCoverage: '200 Park Ave South:',
		sensorLocation: '17th St sidewalk',
		startDate: '2022-09-09', // '2022-07-21'
		endDate: '2022-12-05',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
	},
	{
		locId: '0013-13',
		qName: 'Union Sq Entering (via 200 Park Ave crosswalk)',
		name: 'Union Sq Entering (via 200 Park Ave crosswalk)',
		label: 'Union Sq Entering (via 200 Park Ave crosswalk)',
		abbreviation: 'USQ Entering (via 200 Park crosswalk)',
		client: 'usp',
		color: '#b71c1c',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ Entering:',
		sensorLocation: 'via 200 Park crosswalk',
		startDate: '2022-09-09',
		endDate: '2022-12-05',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
	},
	{
		locId: '0013-14',
		qName: 'Union Sq Exiting (via 200 Park Ave crosswalk)',
		name: 'Union Sq Exiting (via 200 Park Ave crosswalk)',
		label: 'Union Sq Exiting (via 200 Park Ave crosswalk)',
		abbreviation: 'USQ Exiting (via 200 Park crosswalk)',
		client: 'usp',
		color: '#945ecf',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ Exiting:',
		sensorLocation: 'via 200 Park crosswalk',
		startDate: '2022-09-09',
		endDate: '2022-12-05',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
	},
	{
		locId: '0013-15',
		qName: 'Union Sq Entering (via Tammany Hall crosswalk)',
		name: 'Union Sq Entering (via Tammany Hall crosswalk)',
		label: 'Union Sq Entering (via Tammany Hall crosswalk)',
		abbreviation: 'USQ Entering (via Tammany Hall crosswalk)',
		client: 'usp',
		color: '#FFD600',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ Entering:',
		sensorLocation: 'via Tammany Hall crosswalk',
		startDate: '2022-09-09',
		endDate: '2022-12-05',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
	},
	{
		locId: '0013-16',
		qName: 'Union Sq Exiting (via Tammany Hall crosswalk)',
		name: 'Union Sq Exiting (via Tammany Hall crosswalk)',
		label: 'Union Sq Exiting (via Tammany Hall crosswalk)',
		abbreviation: 'USQ Exiting (via Tammany Hall crosswalk)',
		client: 'usp',
		color: '#880E4F',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'USQ Exiting:',
		sensorLocation: 'via Tammany Hall crosswalk',
		startDate: '2022-09-09',
		endDate: '2022-12-05',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
	},
	{
		locId: '0013-17',
		qName: 'Union Sq Northeast Corner',
		name: 'Union Sq Northeast Corner',
		label: 'Union Sq Northeast Corner',
		abbreviation: 'USQ NE Corner',
		client: 'usp',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'Union Square Partnership': [] },
		sensorCoverage: 'Union Sq Northeast Corner:',
		sensorLocation: '',
		startDate: '2022-09-09',
		endDate: '2022-12-05',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
	},
	{
		locId: '0014-1',
		qName: 'Broadway & 31st Intersection',
		name: 'Broadway & 31st Intersection',
		label: 'Broadway & 31st Intersection',
		abbreviation: 'BDWY & 31st Intersection',
		client: 'flatiron',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Koeppel Rosen': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98830430736743, 40.74755566747424] // lon, lat - https://docs.mongodb.com/v4.2/reference/geojson/
		}
	},
	{
		locId: '0014-2',
		qName: '1255 Broadway',
		name: '1255 Broadway',
		label: '1255 Broadway',
		abbreviation: '1255 Broadway',
		client: 'flatiron',
		color: '#E8743B',
		categories: ['Downtown'],
		owners: { 'Koeppel Rosen': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98848582499053, 40.74779243843081]
		},
		subLocation: true
	},
	{
		locId: '0014-3',
		qName: '1255 Broadway (corner)',
		name: '1255 Broadway (corner)',
		label: '1255 Broadway (corner)',
		abbreviation: '1255 BDWY (corner)',
		client: 'flatiron',
		color: '#7986CB',
		categories: ['Downtown'],
		owners: { 'Koeppel Rosen': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98839220927724, 40.74766634557482]
		},
		subsubLocation: true
	},
	{
		locId: '0014-4',
		qName: '1255 Broadway (BDWY sidewalk)',
		name: '1255 Broadway (BDWY sidewalk)',
		label: '1255 Broadway (BDWY sidewalk)',
		abbreviation: '1255 BDWY (BDWY)',
		client: 'flatiron',
		color: '#004d40',
		categories: ['Downtown'],
		owners: { 'Koeppel Rosen': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98836367874733, 40.74779183968847]
		},
		subsubLocation: true
	},
	{
		locId: '0014-5',
		qName: '1255 Broadway (31st sidewalk)',
		name: '1255 Broadway (31st sidewalk)',
		label: '1255 Broadway (31st sidewalk)',
		abbreviation: '1255 BDWY (31st)',
		client: 'flatiron',
		color: '#6d4c41',
		categories: ['Downtown'],
		owners: { 'Koeppel Rosen': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98854893116379, 40.74770928361778]
		},
		subsubLocation: true
	},
	{
		locId: '0014-6',
		qName: '1245 Broadway',
		name: '1245 Broadway',
		label: '1245 Broadway',
		abbreviation: '1245 Broadway',
		client: 'flatiron',
		color: '#b71c1c',
		categories: ['Downtown'],
		owners: { 'Flatiron NoMad Partnership': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98865210780039, 40.7474679007722]
		},
		subLocation: true
	},
	{
		locId: '0014-7',
		qName: '1245 Broadway (corner)',
		name: '1245 Broadway (corner)',
		label: '1245 Broadway (corner)',
		abbreviation: '1245 BDWY (corner)',
		client: 'flatiron',
		color: '#ef5350',
		categories: ['Downtown'],
		owners: { 'Flatiron NoMad Partnership': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98842680188278, 40.7475232732039]
		},
		subsubLocation: true
	},
	{
		locId: '0014-8',
		qName: '1245 Broadway (BDWY sidewalk)',
		name: '1245 Broadway (BDWY sidewalk)',
		label: '1245 Broadway (BDWY sidewalk)',
		abbreviation: '1245 BDWY (BDWY)',
		client: 'flatiron',
		color: '#e1bee7',
		categories: ['Downtown'],
		owners: { 'Flatiron NoMad Partnership': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98844236540617, 40.747368825337226]
		},
		subsubLocation: true
	},
	{
		locId: '0014-9',
		qName: '1245 Broadway (31st sidewalk)',
		name: '1245 Broadway (31st sidewalk)',
		label: '1245 Broadway (31st sidewalk)',
		abbreviation: '1245 BDWY (31st)',
		client: 'flatiron',
		color: '#FFF176',
		categories: ['Downtown'],
		owners: { 'Flatiron NoMad Partnership': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98860724590654, 40.74760224782067]
		},
		subsubLocation: true
	},
	{
		locId: '0014-10',
		qName: 'Nomad Tower (corner)',
		name: 'Nomad Tower (corner)',
		label: 'Nomad Tower (corner)',
		abbreviation: 'Nomad Tower (corner)',
		client: 'flatiron',
		color: '#945ECF',
		categories: ['Downtown'],
		owners: { 'Global Holdings Management Group': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98816662140439, 40.747595080844874]
		},
		subLocation: true
	},
	{
		locId: '0014-11',
		qName: 'Broadway & 31st (North crosswalk)',
		name: 'Broadway & 31st (North crosswalk)',
		label: 'Broadway & 31st (North crosswalk)',
		abbreviation: 'BDWY & 31st (N crosswalk)',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'Flatiron NoMad Partnership': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98827953565304, 40.7476334537895]
		},
		subLocation: true
	},
	{
		locId: '0014-12',
		qName: 'Broadway & 31st (South crosswalk)',
		name: 'Broadway & 31st (South crosswalk)',
		label: 'Broadway & 31st (South crosswalk)',
		abbreviation: 'BDWY & 31st (S crosswalk)',
		client: 'flatiron',
		color: '#D500F9',
		categories: ['Downtown'],
		owners: { 'Flatiron NoMad Partnership': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98831842782461, 40.7474754640888]
		},
		subLocation: true
	},
	{
		locId: '0014-13',
		qName: 'Broadway & 31st (West crosswalk)',
		name: 'Broadway & 31st (West crosswalk)',
		label: 'Broadway & 31st (West crosswalk)',
		abbreviation: 'BDWY & 31st (W crosswalk)',
		client: 'flatiron',
		color: '#283593',
		categories: ['Downtown'],
		owners: { 'Flatiron NoMad Partnership': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98842370440262, 40.74760297353336]
		},
		subLocation: true
	},
	{
		locId: '0014-14',
		qName: 'Broadway & 31st (East crosswalk)',
		name: 'Broadway & 31st (East crosswalk)',
		label: 'Broadway & 31st (East crosswalk)',
		abbreviation: 'BDWY & 31st (E crosswalk)',
		client: 'flatiron',
		color: '#f48fb1',
		categories: ['Downtown'],
		owners: { 'Flatiron NoMad Partnership': [] },
		sensorCoverage: 'Flatiron:',
		sensorLocation: '',
		startDate: '2022-09-26',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98819102286085, 40.74750289632781]
		},
		subLocation: true
	},
	{
		locId: '0014-15',
		qName: '000fp0002L000all',
		name: '28th & PAS Intersection',
		label: '28th & PAS Intersection',
		abbreviation: '28th & PAS Intersection',
		client: 'flatiron',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'flatiron': [] },
		sensorCoverage: 'flatiron:',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98408, 40.74332]
		},
	},
	{
		locId: '0014-16',
		qName: '000fp0002L0000se',
		name: '401 Park Ave S',
		label: '401 Park Ave S',
		abbreviation: '401 PAS',
		client: 'flatiron',
		color: '#FFD600',
		categories: ['Downtown'],
		owners: { 'flatiron': [] },
		sensorCoverage: 'flatiron:',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98397, 40.74319]
		},
		subLocation: true
	},
	{
		locId: '0014-17',
		qName: '000fp0002L000sec',
		name: '401 Park Ave S (corner)',
		label: '401 Park Ave S (corner)',
		abbreviation: '401 PAS (corner)',
		client: 'flatiron',
		color: '#FF4081',
		categories: ['Downtown'],
		owners: { 'flatiron': [] },
		sensorCoverage: '401 Park Ave S (corner):',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98398, 40.74321]
		},
		subsubLocation: true
	},
	{
		locId: '0014-18',
		qName: '000fp0002L00se28',
		name: '401 Park Ave S (28th sidewalk)',
		label: '401 Park Ave S (28th sidewalk)',
		abbreviation: '401 PAS (28th sidewalk)',
		client: 'flatiron',
		color: '#9E9D24',
		categories: ['Downtown'],
		owners: { 'flatiron': [] },
		sensorCoverage: '401 Park Ave S (28th sidewalk):',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98393, 40.74318]
		},
		subsubLocation: true
	},
	{
		locId: '0014-19',
		qName: '000fp0002L00sePA',
		name: '401 Park Ave S (PAS sidewalk)',
		label: '401 Park Ave S (PAS sidewalk)',
		abbreviation: '401 PAS (PAS sidewalk)',
		client: 'flatiron',
		color: '#90a4ae',
		categories: ['Downtown'],
		owners: { 'flatiron': [] },
		sensorCoverage: '401 Park Ave S (PAS sidewalk):',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98402, 40.74316]
		},
		subsubLocation: true
	},
	{
		locId: '0014-20',
		qName: '000fp0002L00seEn',
		name: '28th & PAS Subway (SE) Entance',
		label: '28th & PAS Subway (SE) Entance',
		abbreviation: '28th & PAS Subway (SE) Ent',
		client: 'flatiron',
		color: '#ffb74d',
		categories: ['Downtown'],
		owners: { 'NYC MTA': [] },
		sensorCoverage: '28th & PAS Subway (SE) Entance:',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98392, 40.74319]
		},
		subsubLocation: true
	},
	{
		locId: '0014-21',
		qName: '000fp0002L00seEx',
		name: '28th & PAS Subway (SE) Exit',
		label: '28th & PAS Subway (SE) Exit',
		abbreviation: '28th & PAS Subway (SE) Ex',
		client: 'flatiron',
		color: '#00897b',
		categories: ['Downtown'],
		owners: { 'NYC MTA': [] },
		sensorCoverage: '28th & PAS Subway (SE) Exit:',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98392, 40.74319]
		},
		subsubLocation: true
	},
	{
		locId: '0014-22',
		qName: '000fp0002L000nec',
		name: '407 Park Ave S (corner)',
		label: '407 Park Ave S (corner)',
		abbreviation: '407 PAS (corner)',
		client: 'flatiron',
		color: '#880E4F',
		categories: ['Downtown'],
		owners: { 'flatiron': [] },
		sensorCoverage: '407 Park Ave S (corner):',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.9839, 40.74333]
		},
		subLocation: true
	},
	{
		locId: '0014-23',
		qName: '000fp0002L000nwc',
		name: '404 Park Ave S (corner)',
		label: '404 Park Ave S (corner)',
		abbreviation: '404 PAS (corner)',
		client: 'flatiron',
		color: '#1de9b6',
		categories: ['Downtown'],
		owners: { 'flatiron': [] },
		sensorCoverage: '404 Park Ave S (corner):',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98416, 40.74344]
		},
		subLocation: true
	},
	{
		locId: '0014-24',
		qName: '000fp0002L0000nc',
		name: '28th & PAS (North crosswalk)',
		label: '28th & PAS (North crosswalk)',
		abbreviation: '28th & PAS (N crosswalk)',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'flatiron': [] },
		sensorCoverage: '28th & PAS (North crosswalk):',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98403, 40.74339]
		},
		subLocation: true
	},
	{
		locId: '0014-25',
		qName: '000fp0002L0000sc',
		name: '28th & PAS (South crosswalk)',
		label: '28th & PAS (South crosswalk)',
		abbreviation: '28th & PAS (S crosswalk)',
		client: 'flatiron',
		color: '#D500F9',
		categories: ['Downtown'],
		owners: { 'flatiron': [] },
		sensorCoverage: '28th & PAS (South crosswalk):',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98412, 40.74327]
		},
		subLocation: true
	},
	{
		locId: '0014-26',
		qName: '000fp0002L0000ec',
		name: '28th & PAS (East crosswalk)',
		label: '28th & PAS (East crosswalk)',
		abbreviation: '28th & PAS (E crosswalk)',
		client: 'flatiron',
		color: '#283593',
		categories: ['Downtown'],
		owners: { 'flatiron': [] },
		sensorCoverage: '28th & PAS (East crosswalk):',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98394, 40.74327]
		},
		subLocation: true
	},
	{
		locId: '0014-27',
		qName: '000fp0002L0000wc',
		name: '28th & PAS (West crosswalk)',
		label: '28th & PAS (West crosswalk)',
		abbreviation: '28th & PAS (W crosswalk)',
		client: 'flatiron',
		color: '#f48fb1',
		categories: ['Downtown'],
		owners: { 'flatiron': [] },
		sensorCoverage: '28th & PAS (West crosswalk):',
		sensorLocation: '',
		startDate: '2022-10-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		location: {
			type: 'Point',
			coordinates: [-73.98422, 40.74339]
		},
		subLocation: true
	},
	{
		locId: '0014-28',
		qName: 'NYC - Empire',
		name: '7 W 34th St',
		label: '7 W 34th St',
		abbreviation: '7 W 34th St',
		client: 'flatiron',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'Vornado Realty Trust': [] },
		sensorCoverage: '7 W 34th St:',
		sensorLocation: '7 W 34th St',
		startDate: '2019-12-22',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		location: {
			type: 'Point',
			coordinates: [-73.98543183786956, 40.74888860218385]
		},
		compare: false,
	},
	{
		locId: '0014-29',
		qName: '4 Park Ave',
		name: '4 Park Ave',
		label: '4 Park Ave',
		abbreviation: '4 Park',
		client: 'flatiron',
		color: '#ffd600',
		categories: ['Downtown'],
		owners: { 'The Feil Organization': [] },
		sensorCoverage: '4 Park Ave:',
		sensorLocation: '4 Park Ave',
		startDate: '2021-05-22',
		endDate: '2021-10-31',
		byTimeStart: '2019-01-01T11:00:00.000Z', // 6am
		byTimeEnd: '2019-01-02T04:00:00.000Z', // 11pm
		hrStart: 6,
		hrEnd: 23,
		location: {
			type: 'Point',
			coordinates: [-73.9817648124189, 40.746821962080155]
		},
		compare: false,
	},
	{
		locId: '0014-30',
		qName: [
			'Broadway (at 31st) North Straight', 'Broadway (at 31st) North Left onto 31st', 'Broadway (at 31st) North Right onto 31st',
			'Broadway (at 31st) South Straight', 'Broadway (at 31st) South Left onto 31st', 'Broadway (at 31st) South Right onto 31st'
		],
		name: 'Broadway (at 31st)',
		label: 'Broadway (at 31st)',
		abbreviation: 'Broadway (at 31st)',
		client: 'flatiron',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		// location: {
		// 	type: 'Point',
		// 	coordinates: [-73.98830430736743, 40.74755566747424]
		// },
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north', 'south'],

		expandLocation: true,
		parentOf: ['Broadway - Northbound', 'Broadway - Southbound'],
	},
	{
		locId: '0014-31',
		qName: ['Broadway (at 31st) North Straight', 'Broadway (at 31st) North Left onto 31st', 'Broadway (at 31st) North Right onto 31st'],
		name: 'Broadway - Northbound',
		label: 'Broadway - N',
		abbreviation: 'Broadway - N',
		client: 'flatiron',
		color: '#E8743B',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north'],

		expandLocation: true,
		childOf: ['Broadway (at 31st)'],
		parentOf: ['Broadway - N - Straight', 'Broadway - N - Left onto 31st', 'Broadway - N - Right onto 31st'],
	},
	{
		locId: '0014-32',
		qName: ['Broadway (at 31st) South Straight', 'Broadway (at 31st) South Left onto 31st', 'Broadway (at 31st) South Right onto 31st'],
		name: 'Broadway - Southbound',
		label: 'Broadway - S',
		abbreviation: 'Broadway - S',
		client: 'flatiron',
		color: '#E8743B',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],

		expandLocation: true,
		childOf: ['Broadway (at 31st)'],
		parentOf: ['Broadway - S - Straight', 'Broadway - S - Left onto 31st', 'Broadway - S - Right onto 31st'],
	},
	{
		locId: '0014-33',
		qName: 'Broadway (at 31st) North Straight',
		name: 'Broadway - N - Straight',
		label: 'Broadway - N - Straight',
		abbreviation: 'Broadway - N - Straight',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north'],
		childOf: ['Broadway - Northbound'],
	},
	{
		locId: '0014-34',
		qName: 'Broadway (at 31st) North Left onto 31st',
		name: 'Broadway - N - Left onto 31st',
		label: 'Broadway - N - Left onto 31st',
		abbreviation: 'Broadway - N - Left',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north'],
		childOf: ['Broadway - Northbound'],
	},
	{
		locId: '0014-35',
		qName: 'Broadway (at 31st) North Right onto 31st',
		name: 'Broadway - N - Right onto 31st',
		label: 'Broadway - N - Right onto 31st',
		abbreviation: 'Broadway - N - Right',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north'],
		childOf: ['Broadway - Northbound'],
	},
	{
		locId: '0014-36',
		qName: 'Broadway (at 31st) South Straight',
		name: 'Broadway - S - Straight',
		label: 'Broadway - S - Straight',
		abbreviation: 'Broadway - S - Straight',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
		childOf: ['Broadway - Southbound'],
	},
	{
		locId: '0014-37',
		qName: 'Broadway (at 31st) South Left onto 31st',
		name: 'Broadway - S - Left onto 31st',
		label: 'Broadway - S - Left onto 31st',
		abbreviation: 'Broadway - S - Left',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
		childOf: ['Broadway - Southbound'],
	},
	{
		locId: '0014-38',
		qName: 'Broadway (at 31st) South Right onto 31st',
		name: 'Broadway - S - Right onto 31st',
		label: 'Broadway - S - Right onto 31st',
		abbreviation: 'Broadway - S - Right',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
		childOf: ['Broadway - Southbound'],
	},
	{
		locId: '0014-39',
		qName: [
			'31st (at Broadway) East Straight', '31st (at Broadway) East Left onto Broadway', '31st (at Broadway) East Right onto Broadway',
			'Broadway (at 31st) South Straight', 'Broadway (at 31st) South Left onto 31st', 'Broadway (at 31st) South Right onto 31st'
		],
		name: '31st (at Broadway)',
		label: '31st (at Broadway)',
		abbreviation: '31st (at Broadway)',
		client: 'flatiron',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		// location: {
		// 	type: 'Point',
		// 	coordinates: [-73.98830430736743, 40.74755566747424]
		// },
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['east', 'west'],

		expandLocation: true,
		parentOf: ['31st - Eastbound', '31st - Westbound'],
	},
	{
		locId: '0014-40',
		qName: ['31st (at Broadway) East Straight', '31st (at Broadway) East Left onto Broadway', '31st (at Broadway) East Right onto Broadway'],
		name: '31st - Eastbound',
		label: '31st - E',
		abbreviation: '31st - E',
		client: 'flatiron',
		color: '#E8743B',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['east'],

		expandLocation: true,
		childOf: ['31st (at Broadway)'],
		parentOf: ['31st - E - Straight', '31st - E - Left onto Broadway', '31st - E - Right onto Broadway'],
	},
	{
		locId: '0014-41',
		qName: ['Broadway (at 31st) South Straight', 'Broadway (at 31st) South Left onto 31st', 'Broadway (at 31st) South Right onto 31st'],
		name: '31st - Westbound',
		label: '31st - W',
		abbreviation: '31st - W',
		client: 'flatiron',
		color: '#E8743B',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['west'],

		expandLocation: true,
		childOf: ['31st (at Broadway)'],
		parentOf: ['31st - W - Straight', '31st - W - Left onto Broadway', '31st - W - Right onto Broadway'],
	},
	{
		locId: '0014-42',
		qName: '31st (at Broadway) East Straight',
		name: '31st - E - Straight',
		label: '31st - E - Straight',
		abbreviation: '31st - E - Straight',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['east'],
		childOf: ['31st - Eastbound'],
	},
	{
		locId: '0014-43',
		qName: '31st (at Broadway) East Left onto Broadway',
		name: '31st - E - Left onto Broadway',
		label: '31st - E - Left onto Broadway',
		abbreviation: '31st - E - Left',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['east'],
		childOf: ['31st - Eastbound'],
	},
	{
		locId: '0014-44',
		qName: '31st (at Broadway) East Right onto Broadway',
		name: '31st - E - Right onto Broadway',
		label: '31st - E - Right onto Broadway',
		abbreviation: '31st - E - Right',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['east'],
		childOf: ['31st - Eastbound'],
	},
	{
		locId: '0014-45',
		qName: '31st (at Broadway) West Straight',
		name: '31st - W - Straight',
		label: '31st - W - Straight',
		abbreviation: '31st - W - Straight',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['west'],
		childOf: ['31st - Westbound'],
	},
	{
		locId: '0014-46',
		qName: '31st (at Broadway) West Left onto 31st',
		name: '31st - W - Left onto Broadway',
		label: '31st - W - Left onto Broadway',
		abbreviation: '31st - W - Left',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['west'],
		childOf: ['31st - Westbound'],
	},
	{
		locId: '0014-47',
		qName: '31st (at Broadway) West Right onto Broadway',
		name: '31st - W - Right onto Broadway',
		label: '31st - W - Right onto Broadway',
		abbreviation: '31st - W - Right',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['west'],
		childOf: ['31st - Westbound'],
	},
	{
		locId: '0014-48',
		qName: [
			'Park Ave (at 28th) North Straight', 'Park Ave (at 28th) North Left onto 28th St', 'Park Ave (at 28th) North Right onto 28th St',
			'Park Ave (at 28th) South Straight', 'Park Ave (at 28th) South Left onto 28th St', 'Park Ave (at 28th) South Right onto 28th St'
		],
		name: 'Park Ave (at 28th)',
		label: 'Park Ave (at 28th)',
		abbreviation: 'Park Ave (at 28th)',
		client: 'flatiron',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		// location: {
		// 	type: 'Point',
		// 	coordinates: [-73.98830430736743, 40.74755566747424]
		// },
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north', 'south'],

		expandLocation: true,
		parentOf: ['Park Ave - Northbound', 'Park Ave - Southbound'],
	},
	{
		locId: '0014-49',
		qName: ['Park Ave (at 28th) North Straight', 'Park Ave (at 28th) North Left onto 28th St', 'Park Ave (at 28th) North Right onto 28th St'],
		name: 'Park Ave - Northbound',
		label: 'Park Ave - N',
		abbreviation: 'Park Ave - N',
		client: 'flatiron',
		color: '#E8743B',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north'],

		expandLocation: true,
		childOf: ['Park Ave (at 28th)'],
		parentOf: ['Park Ave - N - Straight', 'Park Ave - N - Left onto 28th St', 'Park Ave - N - Right onto 28th St'],
	},
	{
		locId: '0014-50',
		qName: ['Park Ave (at 28th) South Straight', 'Park Ave (at 28th) South Left onto 28th St', 'Park Ave (at 28th) South Right onto 28th St'],
		name: 'Park Ave - Southbound',
		label: 'Park Ave - S',
		abbreviation: 'Park Ave - S',
		client: 'flatiron',
		color: '#E8743B',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],

		expandLocation: true,
		childOf: ['Park Ave (at 28th)'],
		parentOf: ['Park Ave - S - Straight', 'Park Ave - S - Left onto 28th St', 'Park Ave - S - Right onto 28th St'],
	},
	{
		locId: '0014-51',
		qName: 'Park Ave (at 28th) North Straight',
		name: 'Park Ave - N - Straight',
		label: 'Park Ave - N - Straight',
		abbreviation: 'Park Ave - N - Straight',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north'],
		childOf: ['Park Ave - Northbound'],
	},
	{
		locId: '0014-52',
		qName: 'Park Ave (at 28th) North Left onto 28th St',
		name: 'Park Ave - N - Left onto 28th St',
		label: 'Park Ave - N - Left onto 28th St',
		abbreviation: 'Park Ave - N - Left',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north'],
		childOf: ['Park Ave - Northbound'],
	},
	{
		locId: '0014-53',
		qName: 'Park Ave (at 28th) North Right onto 28th St',
		name: 'Park Ave - N - Right onto 28th St',
		label: 'Park Ave - N - Right onto 28th St',
		abbreviation: 'Park Ave - N - Right',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north'],
		childOf: ['Park Ave - Northbound'],
	},
	{
		locId: '0014-54',
		qName: 'Park Ave (at 28th) South Straight',
		name: 'Park Ave - S - Straight',
		label: 'Park Ave - S - Straight',
		abbreviation: 'Park Ave - S - Straight',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
		childOf: ['Park Ave - Southbound'],
	},
	{
		locId: '0014-55',
		qName: 'Park Ave (at 28th) South Left onto 28th St',
		name: 'Park Ave - S - Left onto 28th St',
		label: 'Park Ave - S - Left onto 28th St',
		abbreviation: 'Park Ave - S - Left',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
		childOf: ['Park Ave - Southbound'],
	},
	{
		locId: '0014-56',
		qName: 'Park Ave (at 28th) South Right onto 28th St',
		name: 'Park Ave - S - Right onto 28th St',
		label: 'Park Ave - S - Right onto 28th St',
		abbreviation: 'Park Ave - S - Right',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
		childOf: ['Park Ave - Southbound'],
	},
	{
		locId: '0014-57',
		qName: [
			'28th St (at Park Ave) East Straight', '28th St (at Park Ave) East Left onto Park Ave', '28th St (at Park Ave) East Right onto Park Ave',
			'28th St (at Park Ave) West Straight', '28th St (at Park Ave) West Left onto Park Ave', '28th St (at Park Ave) West Right onto Park Ave'
		],
		name: '28th St (at Park Ave)',
		label: '28th St (at Park Ave)',
		abbreviation: '28th St (at Park Ave)',
		client: 'flatiron',
		color: '#945ecf',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		// location: {
		// 	type: 'Point',
		// 	coordinates: [-73.98830430736743, 40.74755566747424]
		// },
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['east', 'west'],

		expandLocation: true,
		parentOf: ['28th St - Eastbound', '28th St - Westbound'],
	},
	{
		locId: '0014-58',
		qName: ['28th St (at Park Ave) East Straight', '28th St (at Park Ave) East Left onto Park Ave', '28th St (at Park Ave) East Right onto Park Ave'],
		name: '28th St - Eastbound',
		label: '28th St - E',
		abbreviation: '28th St - E',
		client: 'flatiron',
		color: '#E8743B',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['east'],

		expandLocation: true,
		childOf: ['28th St (at Park Ave)'],
		parentOf: ['28th St - E - Straight', '28th St - E - Left onto Park Ave', '28th St - E - Right onto Park Ave'],
	},
	{
		locId: '0014-59',
		qName: ['28th St (at Park Ave) West Straight', '28th St (at Park Ave) West Left onto Park Ave', '28th St (at Park Ave) West Right onto Park Ave'],
		name: '28th St - Westbound',
		label: '28th St - W',
		abbreviation: '28th St - W',
		client: 'flatiron',
		color: '#E8743B',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['west'],

		expandLocation: true,
		childOf: ['28th St (at Park Ave)'],
		parentOf: ['28th St - W - Straight', '28th St - W - Left onto Park Ave', '28th St - W - Right onto Park Ave'],
	},
	{
		locId: '0014-60',
		qName: '28th St (at Park Ave) East Straight',
		name: '28th St - E - Straight',
		label: '28th St - E - Straight',
		abbreviation: '28th St - E - Straight',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['east'],
		childOf: ['28th St - Eastbound'],
	},
	{
		locId: '0014-61',
		qName: '28th St (at Park Ave) East Left onto Park Ave',
		name: '28th St - E - Left onto Park Ave',
		label: '28th St - E - Left onto Park Ave',
		abbreviation: '28th St - E - Left',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['east'],
		childOf: ['28th St - Eastbound'],
	},
	{
		locId: '0014-62',
		qName: '28th St (at Park Ave) East Right onto Park Ave',
		name: '28th St - E - Right onto Park Ave',
		label: '28th St - E - Right onto Park Ave',
		abbreviation: '28th St - E - Right',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['east'],
		childOf: ['28th St - Eastbound'],
	},
	{
		locId: '0014-63',
		qName: '28th St (at Park Ave) West Straight',
		name: '28th St - W - Straight',
		label: '28th St - W - Straight',
		abbreviation: '28th St - W - Straight',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['west'],
		childOf: ['28th St - Westbound'],
	},
	{
		locId: '0014-64',
		qName: '28th St (at Park Ave) West Left onto Park Ave',
		name: '28th St - W - Left onto Park Ave',
		label: '28th St - W - Left onto Park Ave',
		abbreviation: '28th St - W - Left',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['west'],
		childOf: ['28th St - Westbound'],
	},
	{
		locId: '0014-65',
		qName: '28th St (at Park Ave) West Right onto Park Ave',
		name: '28th St - W - Right onto Park Ave',
		label: '28th St - W - Right onto Park Ave',
		abbreviation: '28th St - W - Right',
		client: 'flatiron',
		color: '#6C8893',
		categories: ['Downtown'],
		owners: { 'NYC': [] },
		startDate: '2023-05-01',
		endDate: '',
		compare: false,

		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['west'],
		childOf: ['28th St - Westbound'],
	},
	{
		locId: '0015-1',
		qName: '157 E Houston St',
		name: '157 E Houston St',
		label: '157 E Houston St',
		abbreviation: '157 E Houston',
		client: 'lee',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Lee': [] },
		sensorCoverage: 'Lee:',
		sensorLocation: '',
		startDate: '2022-10-06',
		endDate: '2022-10-08',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0015-2',
		qName: 'Zazzys Pizza',
		name: 'Zazzy\'s Pizza',
		label: 'Zazzy\'s Pizza',
		abbreviation: 'Zazzy\'s',
		client: 'lee',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'Lee': [] },
		sensorCoverage: 'Zazzy\'s Pizza:',
		sensorLocation: '',
		startDate: '2022-10-06',
		endDate: '2022-10-08',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0015-3',
		qName: 'Sauce Pizzeria',
		name: 'Sauce Pizzeria',
		label: 'Sauce Pizzeria',
		abbreviation: 'Sauce',
		client: 'lee',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'Lee': [] },
		sensorCoverage: 'Sauce Pizzeria:',
		sensorLocation: '',
		startDate: '2022-10-06',
		endDate: '2022-10-08',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0015-4',
		qName: 'Scarrs Pizza',
		name: 'Scarr\'s Pizza',
		label: 'Scarr\'s Pizza',
		abbreviation: 'Scarr\'s',
		client: 'lee',
		color: '#b71c1c',
		categories: ['Downtown'],
		owners: { 'Lee': [] },
		sensorCoverage: 'Scarr\'s Pizza:',
		sensorLocation: '',
		startDate: '2022-10-06',
		endDate: '2022-10-08',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-1',
		qName: '380 2nd Lobby Entering',
		name: '380 2nd Lobby Entering',
		label: '380 2nd Lobby Entering',
		abbreviation: '380 2nd Lbby Enter',
		client: 'abs',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-2',
		qName: '380 2nd Lobby Exiting',
		name: '380 2nd Lobby Exiting',
		label: '380 2nd Lobby Exiting',
		abbreviation: '380 2nd Lbby Exit',
		client: 'abs',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-3',
		qName: '380 2nd Right Elevator Entering',
		name: '380 2nd Right Elevator Entering',
		label: '380 2nd Right Elevator Entering',
		abbreviation: '380 2nd R Elev Enter',
		client: 'abs',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-4',
		qName: '380 2nd Left Elevator Entering',
		name: '380 2nd Left Elevator Entering',
		label: '380 2nd Left Elevator Entering',
		abbreviation: '380 2nd L Elev Enter',
		client: 'abs',
		color: '#b71c1c',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-5',
		qName: '380 2nd Right Elevator Exiting',
		name: '380 2nd Right Elevator Exiting',
		label: '380 2nd Right Elevator Exiting',
		abbreviation: '380 2nd R Elev Exit',
		client: 'abs',
		color: '#945ecf',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-6',
		qName: '380 2nd Left Elevator Exiting',
		name: '380 2nd Left Elevator Exiting',
		label: '380 2nd Left Elevator Exiting',
		abbreviation: '380 2nd L Elev Exit',
		client: 'abs',
		color: '#ffd600',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-7',
		qName: '310 22nd Lobby Entering',
		name: '310 22nd Lobby Entering',
		label: '310 22nd Lobby Entering',
		abbreviation: '310 22nd Lbby Enter',
		client: 'abs',
		color: '#880e4f',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-8',
		qName: '310 22nd Lobby Exiting',
		name: '310 22nd Lobby Exiting',
		label: '310 22nd Lobby Exiting',
		abbreviation: '310 22nd Lbby Exiting',
		client: 'abs',
		color: '#1de9b6',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-9',
		qName: '310 22nd Loading Dock Entering',
		name: '310 22nd Loading Dock Entering',
		label: '310 22nd Loading Dock Entering',
		abbreviation: '310 22nd Ldng Dock Enter',
		client: 'abs',
		color: '#6c8893',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-10',
		qName: '310 22nd Loading Dock Exiting',
		name: '310 22nd Loading Dock Exiting',
		label: '310 22nd Loading Dock Exiting',
		abbreviation: '310 22nd Ldng Dock Exit',
		client: 'abs',
		color: '#d500f9',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-11',
		qName: '310 22nd Right Elevator Entering',
		name: '310 22nd Right Elevator Entering',
		label: '310 22nd Right Elevator Entering',
		abbreviation: '310 22nd R Elev Enter',
		client: 'abs',
		color: '#283593',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-12',
		qName: '310 22nd Left Elevator Entering',
		name: '310 22nd Left Elevator Entering',
		label: '310 22nd Left Elevator Entering',
		abbreviation: '310 22nd L Elev Enter',
		client: 'abs',
		color: '#f48fb1',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-13',
		qName: '310 22nd Right Elevator Exiting',
		name: '310 22nd Right Elevator Exiting',
		label: '310 22nd Right Elevator Exiting',
		abbreviation: '310 22nd R Elev Exit',
		client: 'abs',
		color: '#7986CB',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0017-14',
		qName: '310 22nd Left Elevator Exiting',
		name: '310 22nd Left Elevator Exiting',
		label: '310 22nd Left Elevator Exiting',
		abbreviation: '310 22nd L Elev Exit',
		client: 'abs',
		color: '#004d40',
		categories: ['Downtown'],
		owners: { 'ABS': [] },
		sensorCoverage: 'ABS:',
		sensorLocation: '',
		startDate: '2022-10-15',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0018-1',
		name: 'Indian Land Entering',
		label: 'Indian Land Entering',
		abbreviation: 'Indian Land Entering',
		client: 'goodwill',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'Goodwill': [] },
		startDate: '2023-06-17',
		endDate: '2023-08-17',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 21,
	},
	{
		locId: '0018-2',
		name: 'Indian Land Exiting',
		label: 'Indian Land Exiting',
		abbreviation: 'Indian Land Exiting',
		client: 'goodwill',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'Goodwill': [] },
		startDate: '2023-06-17',
		endDate: '2023-08-17',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 21
	},
	{
		locId: '0018-3',
		name: 'Indian Land Drop-off',
		label: 'Indian Land Drop-off',
		abbreviation: 'Indian Land Drop-off',
		client: 'goodwill',
		color: '#e8743b',
		categories: ['Downtown'],
		owners: { 'Goodwill': [] },
		startDate: '2023-06-17',
		endDate: '2023-08-17',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 21
	},
	// {
	// 	locId: '0018-4',
	// 	name: 'Indian Land Drop-off Entering Queue',
	// 	label: 'Indian Land Drop-off Entering Queue',
	// 	abbreviation: 'Indian Land Drop-off Entering Queue',
	// 	client: 'goodwill',
	// 	color: '#b71c1c',
	// 	categories: ['Downtown'],
	// 	owners: { 'Goodwill': [] },
	// 	startDate: '2023-06-20',
	// 	endDate: '',
	// 	byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
	// 	byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
	// 	hrStart: 7,
	// 	hrEnd: 22
	// },
	// {
	// 	locId: '0018-5',
	// 	name: 'Indian Land Drop-off Average Queue',
	// 	label: 'Indian Land Drop-off Average Queue',
	// 	abbreviation: 'Indian Land Drop-off Average Queue',
	// 	client: 'goodwill',
	// 	color: '#945ecf',
	// 	categories: ['Downtown'],
	// 	owners: { 'Goodwill': [] },
	// 	startDate: '2023-06-20',
	// 	endDate: '',
	// 	byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
	// 	byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
	// 	hrStart: 7,
	// 	hrEnd: 22,
	// 	occupancy: true,
	// },
	{
		locId: '0019-1',
		// qName: [
		// 	'33rd & Broadway Entering North', '33rd & Broadway Entering South', '33rd & Broadway Entering East', '33rd & Broadway Entering West',
		// 	'34th & Broadway Entering North', '34th & Broadway Entering South', '34th & Broadway Entering East', '34th & Broadway Entering West',
		// ],
		qName: [
			'33rd & Broadway Entering North (East sidewalk)', '33rd & Broadway Entering North (West sidewalk)',
			'33rd & Broadway Entering South (East sidewalk)', '33rd & Broadway Entering South (West sidewalk)',
			'33rd & Broadway Entering East (North sidewalk)', '33rd & Broadway Entering East (South sidewalk)',
			'33rd & Broadway Entering West (North sidewalk)', '33rd & Broadway Entering West (South sidewalk)',
			'34th & Broadway Entering North (East sidewalk)', '34th & Broadway Entering North (West sidewalk)',
			'34th & Broadway Entering South (East sidewalk)', '34th & Broadway Entering South (West sidewalk)',
			'34th & Broadway Entering East (North sidewalk)', '34th & Broadway Entering East (South sidewalk)',
			'34th & Broadway Entering West (North sidewalk)', '34th & Broadway Entering West (South sidewalk)',
		],
		name: 'Herald Square Total',
		label: 'Herald Square Total',
		abbreviation: 'Herald Square Total',
		client: 'jemb',
		color: primaryColors[0],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		compare: false,
		hideDemographics: true
	},
	{
		locId: '0019-15',
		// qName: ['34th & Broadway Entering North', '34th & Broadway Entering South', '34th & Broadway Entering East', '34th & Broadway Entering West'],
		qName: [
			'34th & Broadway Entering North (East sidewalk)', '34th & Broadway Entering North (West sidewalk)',
			'34th & Broadway Entering South (East sidewalk)', '34th & Broadway Entering South (West sidewalk)',
			'34th & Broadway Entering East (North sidewalk)', '34th & Broadway Entering East (South sidewalk)',
			'34th & Broadway Entering West (North sidewalk)', '34th & Broadway Entering West (South sidewalk)'
		],
		name: '34th, Broadway, & 6th Intersection',
		label: '34th, Broadway, & 6th Intersection',
		abbreviation: '34th, BDWY, & 6th Intersection',
		client: 'jemb',
		color: primaryColors[1],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		expandLocation: true,
		defaultOpen: true,
		parentOf: ['34th & Broadway Entering North', '34th & Broadway Entering South', '34th & Broadway Entering East', '34th & Broadway Entering West'],
		hideDemographics: true
	},
	{
		locId: '0019-16',
		qName: ['34th & Broadway Entering North (East sidewalk)', '34th & Broadway Entering North (West sidewalk)'],
		name: '34th & Broadway Entering North',
		label: '34th & Broadway Entering North',
		abbreviation: '34th & BDWY Entering North',
		client: 'jemb',
		color: primaryColors[3],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		childOf: ['34th, Broadway, & 6th Intersection'],
		expandLocation: true,
		// defaultOpen: true,
		parentOf: ['34th North (East sidewalk)', '34th North (West sidewalk)'],
    paired: true,
		hideDemographics: true
	},
	{
		locId: '0019-17',
		qName: '34th & Broadway Entering North (East sidewalk)',
		name: '34th North (East sidewalk)',
		label: '34th & Broadway Entering North (East sidewalk)',
		abbreviation: '34th & BDWY Entering North (East sidewalk)',
		client: 'jemb',
		color: secondaryColors[0],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['34th & Broadway Entering North'],
		hideDemographics: true
	},
	{
		locId: '0019-18',
		qName: '34th & Broadway Entering North (West sidewalk)',
		name: '34th North (West sidewalk)',
		label: '34th & Broadway Entering North (West sidewalk)',
		abbreviation: '34th & BDWY Entering North (West sidewalk)',
		client: 'jemb',
		color: secondaryColors[10],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['34th & Broadway Entering North'],
		hideDemographics: true
	},
	{
		locId: '0019-19',
		qName: ['34th & Broadway Entering South (East sidewalk)', '34th & Broadway Entering South (West sidewalk)'],
		name: '34th & Broadway Entering South',
		label: '34th & Broadway Entering South',
		abbreviation: '34th & BDWY Entering South',
		client: 'jemb',
		color: primaryColors[5],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		childOf: ['34th, Broadway, & 6th Intersection'],
		expandLocation: true,
		// defaultOpen: true,
		parentOf: ['34th South (East sidewalk)', '34th South (West sidewalk)'],
    paired: true,
		hideDemographics: true
	},
	{
		locId: '0019-20',
		qName: '34th & Broadway Entering South (East sidewalk)',
		name: '34th South (East sidewalk)',
		label: '34th & Broadway Entering South (East sidewalk)',
		abbreviation: '34th & BDWY Entering South (East sidewalk)',
		client: 'jemb',
		color: secondaryColors[1],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['34th & Broadway Entering South'],
		hideDemographics: true
	},
	{
		locId: '0019-21',
		qName: '34th & Broadway Entering South (West sidewalk)',
		name: '34th South (West sidewalk)',
		label: '34th & Broadway Entering South (West sidewalk)',
		abbreviation: '34th & BDWY Entering South (West sidewalk)',
		client: 'jemb',
		color: secondaryColors[11],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['34th & Broadway Entering South'],
		hideDemographics: true
	},
	{
		locId: '0019-22',
		qName: ['34th & Broadway Entering East (North sidewalk)', '34th & Broadway Entering East (South sidewalk)'],
		name: '34th & Broadway Entering East',
		label: '34th & Broadway Entering East',
		abbreviation: '34th & Broadway Entering East',
		client: 'jemb',
		color: primaryColors[7],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		childOf: ['34th, Broadway, & 6th Intersection'],
		expandLocation: true,
		// defaultOpen: true,
		parentOf: ['34th East (North sidewalk)', '34th East (South sidewalk)'],
    paired: true,
		hideDemographics: true
	},
	{
		locId: '0019-23',
		qName: '34th & Broadway Entering East (North sidewalk)',
		name: '34th East (North sidewalk)',
		label: '34th & Broadway Entering East (North sidewalk)',
		abbreviation: '34th & BDWY Entering East (North sidewalk)',
		client: 'jemb',
		color: secondaryColors[2],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['34th & Broadway Entering East'],
		hideDemographics: true
	},
	{
		locId: '0019-24',
		qName: '34th & Broadway Entering East (South sidewalk)',
		name: '34th East (South sidewalk)',
		label: '34th & Broadway Entering East (South sidewalk)',
		abbreviation: '34th & BDWY Entering East (South sidewalk)',
		client: 'jemb',
		color: secondaryColors[8],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['34th & Broadway Entering East'],
		hideDemographics: true
	},
	{
		locId: '0019-25',
		qName: ['34th & Broadway Entering West (North sidewalk)', '34th & Broadway Entering West (South sidewalk)'],
		name: '34th & Broadway Entering West',
		label: '34th & Broadway Entering West',
		abbreviation: '34th & Broadway Entering West',
		client: 'jemb',
		color: primaryColors[9],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		childOf: ['34th, Broadway, & 6th Intersection'],
		expandLocation: true,
		// defaultOpen: true,
		parentOf: ['34th West (North sidewalk)', '34th West (South sidewalk)'],
    paired: true,
		hideDemographics: true
	},
	{
		locId: '0019-26',
		qName: '34th & Broadway Entering West (North sidewalk)',
		name: '34th West (North sidewalk)',
		label: '34th & Broadway Entering West (North sidewalk)',
		abbreviation: '34th & BDWY Entering West (North sidewalk)',
		client: 'jemb',
		color: secondaryColors[3],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['34th & Broadway Entering West'],
		hideDemographics: true
	},
	{
		locId: '0019-27',
		qName: '34th & Broadway Entering West (South sidewalk)',
		name: '34th West (South sidewalk)',
		label: '34th & Broadway Entering West (South sidewalk)',
		abbreviation: '34th & BDWY Entering West (South sidewalk)',
		client: 'jemb',
		color: primaryColors[11],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['34th & Broadway Entering West'],
		hideDemographics: true
	},
	{
		locId: '0019-2',
		// qName: ['33rd & Broadway Entering North', '33rd & Broadway Entering South', '33rd & Broadway Entering East', '33rd & Broadway Entering West'],
		qName: [
			'33rd & Broadway Entering North (East sidewalk)', '33rd & Broadway Entering North (West sidewalk)',
			'33rd & Broadway Entering South (East sidewalk)', '33rd & Broadway Entering South (West sidewalk)',
			'33rd & Broadway Entering East (North sidewalk)', '33rd & Broadway Entering East (South sidewalk)',
			'33rd & Broadway Entering West (North sidewalk)', '33rd & Broadway Entering West (South sidewalk)'
		],
		name: '33rd, 6th, & Broadway Intersection',
		label: '33rd, 6th, & Broadway Intersection',
		abbreviation: '33rd, 6th, & BDWY Intersection',
		client: 'jemb',
		color: primaryColors[2],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		expandLocation: true,
		defaultOpen: true,
		parentOf: ['33rd & Broadway Entering North', '33rd & Broadway Entering South', '33rd & Broadway Entering East', '33rd & Broadway Entering West'],
		hideDemographics: true
	},
	{
		locId: '0019-3',
		qName: ['33rd & Broadway Entering North (East sidewalk)', '33rd & Broadway Entering North (West sidewalk)'],
		name: '33rd & Broadway Entering North',
		label: '33rd & Broadway Entering North',
		abbreviation: '33rd & Broadway Entering North',
		client: 'jemb',
		color: primaryColors[4],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		childOf: ['33rd, 6th, & Broadway Intersection'],
		expandLocation: true,
		// defaultOpen: true,
		parentOf: ['33rd North (East sidewalk)', '33rd North (West sidewalk)'],
    paired: true,
		hideDemographics: true
	},
	{
		locId: '0019-4',
		qName: '33rd & Broadway Entering North (East sidewalk)',
		name: '33rd North (East sidewalk)',
		label: '33rd & Broadway Entering North (East sidewalk)',
		abbreviation: '33rd & BDWY Entering North (East sidewalk)',
		client: 'jemb',
		color: secondaryColors[9],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['33rd & Broadway Entering North'],
		hideDemographics: true
	},
	{
		locId: '0019-5',
		qName: '33rd & Broadway Entering North (West sidewalk)',
		name: '33rd North (West sidewalk)',
		label: '33rd & Broadway Entering North (West sidewalk)',
		abbreviation: '33rd & BDWY Entering North (West sidewalk)',
		client: 'jemb',
		color: secondaryColors[4],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['33rd & Broadway Entering North'],
		hideDemographics: true
	},
	{
		locId: '0019-6',
		qName: ['33rd & Broadway Entering South (East sidewalk)', '33rd & Broadway Entering South (West sidewalk)'],
		name: '33rd & Broadway Entering South',
		label: '33rd & Broadway Entering South',
		abbreviation: '33rd & BDWY Entering South',
		client: 'jemb',
		color: primaryColors[6],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		childOf: ['33rd, 6th, & Broadway Intersection'],
		expandLocation: true,
		// defaultOpen: true,
		parentOf: ['33rd South (East sidewalk)', '33rd South (West sidewalk)'],
    paired: true,
		hideDemographics: true
	},
	{
		locId: '0019-7',
		qName: '33rd & Broadway Entering South (East sidewalk)',
		name: '33rd South (East sidewalk)',
		label: '33rd & Broadway Entering South (East sidewalk)',
		abbreviation: '33rd & BDWY Entering South (East sidewalk)',
		client: 'jemb',
		color: secondaryColors[6],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['33rd & Broadway Entering South'],
		hideDemographics: true
	},
	{
		locId: '0019-8',
		qName: '33rd & Broadway Entering South (West sidewalk)',
		name: '33rd South (West sidewalk)',
		label: '33rd & Broadway Entering South (West sidewalk)',
		abbreviation: '33rd & BDWY Entering South (West sidewalk)',
		client: 'jemb',
		color: secondaryColors[5],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['33rd & Broadway Entering South'],
		hideDemographics: true
	},
	{
		locId: '0019-9',
		qName: ['33rd & Broadway Entering East (North sidewalk)', '33rd & Broadway Entering East (South sidewalk)'],
		name: '33rd & Broadway Entering East',
		label: '33rd & Broadway Entering East',
		abbreviation: '33rd & BDWY Entering East',
		client: 'jemb',
		color: secondaryColors[7],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		childOf: ['33rd, 6th, & Broadway Intersection'],
		expandLocation: true,
		// defaultOpen: true,
		parentOf: ['33rd East (North sidewalk)', '33rd East (South sidewalk)'],
    paired: true,
		hideDemographics: true
	},
	{
		locId: '0019-10',
		qName: '33rd & Broadway Entering East (North sidewalk)',
		name: '33rd East (North sidewalk)',
		label: '33rd & Broadway Entering East (North sidewalk)',
		abbreviation: '33rd & BDWY Entering East (North sidewalk)',
		client: 'jemb',
		color: secondaryColors[12],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['33rd & Broadway Entering East'],
		hideDemographics: true
	},
	{
		locId: '0019-11',
		qName: '33rd & Broadway Entering East (South sidewalk)',
		name: '33rd East (South sidewalk)',
		label: '33rd & Broadway Entering East (South sidewalk)',
		abbreviation: '33rd & BDWY Entering East (South sidewalk)',
		client: 'jemb',
		color: secondaryColors[13],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['33rd & Broadway Entering East'],
		hideDemographics: true
	},
	{
		locId: '0019-12',
		qName: ['33rd & Broadway Entering West (North sidewalk)', '33rd & Broadway Entering West (South sidewalk)'],
		name: '33rd & Broadway Entering West',
		label: '33rd & Broadway Entering West',
		abbreviation: '33rd & BDWY Entering West',
		client: 'jemb',
		color: primaryColors[10],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		childOf: ['33rd, 6th, & Broadway Intersection'],
		expandLocation: true,
		// defaultOpen: true,
		parentOf: ['33rd West (North sidewalk)', '33rd West (South sidewalk)'],
    paired: true,
		hideDemographics: true
	},
	{
		locId: '0019-13',
		qName: '33rd & Broadway Entering West (North sidewalk)',
		name: '33rd West (North sidewalk)',
		label: '33rd & Broadway Entering West (North sidewalk)',
		abbreviation: '33rd & BDWY Entering West (North sidewalk)',
		client: 'jemb',
		color: primaryColors[12],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['33rd & Broadway Entering West'],
		hideDemographics: true
	},
	{
		locId: '0019-14',
		qName: '33rd & Broadway Entering West (South sidewalk)',
		name: '33rd West (South sidewalk)',
		label: '33rd & Broadway Entering West (South sidewalk)',
		abbreviation: '33rd & BDWY Entering West (South sidewalk)',
		client: 'jemb',
		color: primaryColors[13],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2023-07-25',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
    childOf: ['33rd & Broadway Entering West'],
		hideDemographics: true
	},
	{
		locId: '0019-30',
		qName: [
			'Zuccotti Park East', 'Broadway South Sidewalk',
			'Broadway North Sidewalk', 'Harry B Helmsley Plaza', 'Liberty Street East Sidewalk'
		],
		name: '150 Broadway',
		label: '150 Broadway',
		abbreviation: '150 Broadway',
		client: 'jemb',
		color: primaryColors[3],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2024-01-29',
		endDate: '',
		compare: false,
		expandLocation: true,
		defaultOpen: true,
		parentOf: [
			'Broadway North Sidewalk', 'Broadway South Sidewalk',
			'Zuccotti Park East', 'Harry B Helmsley Plaza', 'Liberty Street East Sidewalk'
		]
	},
	{
		locId: '0019-31',
		qName: 'Broadway North Sidewalk',
		name: 'Broadway North Sidewalk',
		label: 'Broadway North Sidewalk',
		abbreviation: 'Broadway N Sidewalk',
		client: 'jemb',
		color: primaryColors[4],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2024-01-29',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		childOf: ['150 Broadway'],
	},
	{
		locId: '0019-32',
		qName: 'Broadway South Sidewalk',
		name: 'Broadway South Sidewalk',
		label: 'Broadway South Sidewalk',
		abbreviation: 'Broadway S Sidewalk',
		client: 'jemb',
		color: primaryColors[5],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2024-01-29',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		childOf: ['150 Broadway'],
	},
	{
		locId: '0019-33',
		qName: 'Zuccotti Park East',
		name: 'Zuccotti Park East',
		label: 'Zuccotti Park East',
		abbreviation: 'Zuccotti Park E',
		client: 'jemb',
		color: primaryColors[6],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2024-01-29',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		childOf: ['150 Broadway'],
	},
	{
		locId: '0019-34',
		qName: 'Harry B Helmsley Plaza',
		name: 'Harry B Helmsley Plaza',
		label: 'Harry B Helmsley Plaza',
		abbreviation: 'Harry B Helmsley Plaza',
		client: 'jemb',
		color: primaryColors[7],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2024-01-29',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		childOf: ['150 Broadway'],
	},
	{
		locId: '0019-35',
		qName: 'Liberty Street East Sidewalk',
		name: 'Liberty Street East Sidewalk',
		label: 'Liberty Street East Sidewalk',
		abbreviation: 'Liberty St E Sidewalk',
		client: 'jemb',
		color: primaryColors[8],
		categories: ['Downtown'],
		owners: { 'JEMB': [] },
		startDate: '2024-01-29',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		childOf: ['150 Broadway'],
	},
	{
		name: 'Other Locations',
		label: 'Other Locations',
		abbreviation: 'Other Locations',
		client: 'jemb',
		compare: false,
		archived: false,
		expandLabel: true,
		parentOf: ['Broadway & 31st Intersection ', '34th St (N Sidewalk btw 5th & 6th)'], // 1st loc whitespace intentional to match loc name
	},
	{
		locId: '0019-28',
		qName: 'Broadway & 31st Intersection',
		name: 'Broadway & 31st Intersection ', // whitespace intentionally added for name to be unique
		label: 'Broadway & 31st Intersection',
		abbreviation: 'BDWY & 31st Intersection',
		client: 'jemb',
		color: primaryColors[3],
		categories: ['Downtown'],
		owners: { 'Koeppel Rosen': [] },
		startDate: '2022-09-26',
		endDate: '',
		compare: false,
		childOf: ['Other Locations'],
		byTimeStart: '2019-01-01T05:00:00.000Z', // midnight
		byTimeEnd: '2019-01-02T05:00:00.000Z', // midnight
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0019-29',
		qName: 'NYC - Empire',
		name: '34th St (N Sidewalk btw 5th & 6th)',
		label: '34th St (N Sidewalk btw 5th & 6th)',
		abbreviation: '34th St (N Sidewalk btw 5th & 6th)',
		client: 'jemb',
		color: primaryColors[4],
		categories: ['Downtown'],
		owners: { 'Vornado Realty Trust': [] },
		startDate: '2019-12-22',
		endDate: '',
		compare: false,
		childOf: ['Other Locations'],
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
	},
	{
		locId: '0020-1',
		name: '510 Fifth Ave',
		label: '510 Fifth Ave',
		abbreviation: '510 Fifth Ave',
		client: 'jll',
		color: '#5899da',
		categories: ['Downtown'],
		owners: { 'JLL': [] },
		startDate: '2023-08-03',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 9,
		hrEnd: 22,
	},
	{
		locId: '0020-2',
		name: '584 Broadway',
		qName: '584 Broadway',
		label: '584 Broadway',
		abbreviation: '584 Broadway',
		client: 'jll',
		color: '#19a979',
		categories: ['Downtown'],
		owners: { 'JLL': [] },
		startDate: '2023-08-05',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 9,
		hrEnd: 22,
	},
	{
		locId: '0021-1',
		name: 'Lincoln Square Bowtie Total',
		qName: 'Lincoln Square Bowtie Total',
		label: 'Lincoln Square Bowtie Total',
		abbreviation: 'Lincoln Square Bowtie Total',
		client: 'lsbid',
		color: primaryColors[0],
		categories: ['Downtown'],
		owners: { 'LSBID': [] },
		startDate: '2023-09-01',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 7,
		hrEnd: 19
	},
	{
		locId: '0021-2',
		name: 'Broadway Sidewalk',
		qName: 'Broadway Sidewalk',
		label: 'Broadway Sidewalk',
		abbreviation: 'Broadway Sidewalk',
		client: 'lsbid',
		color: primaryColors[2],
		categories: ['Downtown'],
		owners: { 'LSBID': [] },
		startDate: '2023-09-01',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 7,
		hrEnd: 19,
		subLocation: true,
	},
	{
		locId: '0021-3',
		name: '64th Street Pedestrian Crossing',
		qName: '64th Street Pedestrian Crossing',
		label: '64th Street Pedestrian Crossing',
		abbreviation: '64th St Ped Crossing',
		client: 'lsbid',
		color: primaryColors[3],
		categories: ['Downtown'],
		owners: { 'LSBID': [] },
		startDate: '2023-09-01',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 7,
		hrEnd: 19,
		subLocation: true,
	},
	{
		locId: '0021-4',
		name: 'Dante Park',
		qName: 'Dante Park',
		label: 'Dante Park',
		abbreviation: 'Dante Park',
		client: 'lsbid',
		color: primaryColors[1],
		categories: ['Downtown'],
		owners: { 'LSBID': [] },
		startDate: '2023-09-01',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 7,
		hrEnd: 19,
		subLocation: true,
	},
	{
		locId: '0021-5',
		name: 'Columbus West Sidewalk',
		qName: 'Columbus West Sidewalk',
		label: 'Columbus West Sidewalk',
		abbreviation: 'Columbus W Sidewalk',
		client: 'lsbid',
		color: primaryColors[4],
		categories: ['Downtown'],
		owners: { 'LSBID': [] },
		startDate: '2023-09-01',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 7,
		hrEnd: 19,
		subLocation: true,
	},
	{
		locId: '0021-9',
		name: 'Southbound Traffic',
		qName: ['Columbus Ave', 'Broadway South'],
		label: 'Southbound Traffic',
		abbreviation: 'Southbound Traffic',
		client: 'lsbid',
		color: primaryColors[0],
		categories: ['Downtown'],
		owners: { 'LSBID': [] },
		startDate: '2023-09-01',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 7,
		hrEnd: 19,
		expandLocation: true,
		defaultOpen: true,
		compare: false,
		parentOf: ['Columbus Ave', 'Broadway South'],
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0021-6',
		name: 'Columbus Ave',
		qName: 'Columbus Ave',
		label: 'Columbus Ave',
		abbreviation: 'Columbus Ave',
		client: 'lsbid',
		color: primaryColors[1],
		categories: ['Downtown'],
		owners: { 'LSBID': [] },
		startDate: '2023-09-01',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 7,
		hrEnd: 19,
		compare: false,
		childOf: ['South Bound'],
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0021-8',
		name: 'Broadway South',
		qName: 'Broadway South',
		label: 'Broadway South',
		abbreviation: 'Broadway South',
		client: 'lsbid',
		color: primaryColors[2],
		categories: ['Downtown'],
		owners: { 'LSBID': [] },
		startDate: '2023-09-01',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 7,
		hrEnd: 19,
		compare: false,
		childOf: ['South Bound'],
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0021-7',
		name: 'Broadway North',
		qName: 'Broadway North',
		label: 'Broadway North',
		abbreviation: 'Broadway North',
		client: 'lsbid',
		color: primaryColors[3],
		categories: ['Downtown'],
		owners: { 'LSBID': [] },
		startDate: '2023-09-01',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T00:00:00.000Z', // 7pm
		hrStart: 7,
		hrEnd: 19,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north'],
	},
	{
		locId: '0022-1',
		name: 'Route 119',
		qName: ['Route 119 (Southbound)', 'Route 119 (Northbound)'],
		label: 'Route 119',
		abbreviation: 'Route 119',
		client: 'dgd',
		color: primaryColors[0],
		categories: ['Downtown'],
		owners: { 'State of New Hampshire': [] },
		startDate: '2023-08-26',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T01:00:00.000Z', // 8pm
		hrStart: 7,
		hrEnd: 22,
		expandLocation: true,
		defaultOpen: true,
		compare: false,
		parentOf: ['Route 119 (Southbound)', 'Route 119 (Northbound)'],
		traffic: ['vehicle'],
		vehicles: ['motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north', 'south'],
	},
	{
		locId: '0022-2',
		name: 'Route 119 (Southbound)',
		qName: 'Route 119 (Southbound)',
		label: 'Route 119 (Southbound)',
		abbreviation: 'Route 119 (Southbound)',
		client: 'dgd',
		color: primaryColors[1],
		categories: ['Downtown'],
		owners: { 'State of New Hampshire': [] },
		startDate: '2023-08-26',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T01:00:00.000Z', // 8pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		childOf: ['Route 119'],
		traffic: ['vehicle'],
		vehicles: ['motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0022-3',
		name: 'Route 119 (Northbound)',
		qName: 'Route 119 (Northbound)',
		label: 'Route 119 (Northbound)',
		abbreviation: 'Route 119 (Northbound)',
		client: 'dgd',
		color: primaryColors[2],
		categories: ['Downtown'],
		owners: { 'State of New Hampshire': [] },
		startDate: '2023-08-26',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T01:00:00.000Z', // 8pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		childOf: ['Route 119'],
		traffic: ['vehicle'],
		vehicles: ['motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['north'],
	},
	{
		locId: '0022-4',
		name: 'George\'s Field Entering',
		qName: 'George\'s Field Entering',
		label: 'George\'s Field Entering',
		abbreviation: 'George\'s Field Entering',
		client: 'dgd',
		color: primaryColors[3],
		categories: ['Shopping Malls'],
		owners: { 'Deborah George Development': [] },
		startDate: '2023-08-26',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T01:00:00.000Z', // 8pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0022-5',
		name: 'George\'s Field Exiting',
		qName: 'George\'s Field Exiting',
		label: 'George\'s Field Exiting',
		abbreviation: 'George\'s Field Exiting',
		client: 'dgd',
		color: primaryColors[4],
		categories: ['Shopping Malls'],
		owners: { 'Deborah George Development': [] },
		startDate: '2023-08-26',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T01:00:00.000Z', // 8pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0023-1',
		qName: ['Fifth Ave & 43rd St Eastside', '510 Fifth Ave'],
		name: 'Fifth Ave & 43rd St Intersection',
		label: 'Fifth Ave & 43rd St Intersection',
		abbreviation: 'Fifth Ave & 43rd St Intersection',
		client: 'cw',
		color: primaryColors[0],
		categories: ['Downtown'],
		owners: { 'Fifth Avenue': [] },
		startDate: '2023-10-26',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 21,
		parentOf: ['Fifth Ave & 43rd St Eastside', 'Fifth Ave & 43rd St Westside'],
		compare: false,
		expandLocation: true,
		defaultOpen: true,
	},
	{
		locId: '0023-2',
		qName: 'Fifth Ave & 43rd St Eastside',
		name: 'Fifth Ave & 43rd St Eastside',
		label: 'Fifth Ave & 43rd St Eastside',
		abbreviation: 'Fifth Ave & 43rd St Eastside',
		client: 'cw',
		color: primaryColors[2],
		categories: ['Downtown'],
		owners: { 'Jeff Sutton & Aurora Capital (511 Fifth)': [] },
		startDate: '2023-10-26',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		compare: true,
		childOf: ['Fifth Ave & 43rd St Intersection'],
	},
	{
		locId: '0023-3',
		qName: '510 Fifth Ave',
		name: 'Fifth Ave & 43rd St Westside',
		label: 'Fifth Ave & 43rd St Westside',
		abbreviation: 'Fifth Ave & 43rd St Westside',
		client: 'cw',
		color: primaryColors[4],
		categories: ['Downtown'],
		owners: { 'Reuben Bros. (510 Fifth)': [] },
		startDate: '2023-10-26',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		compare: true,
		childOf: ['Fifth Ave & 43rd St Intersection'],
	},
	{
		locId: '0023-4',
		qName: ['Fifth Ave & 47th St Eastside', 'Fifth Ave & 47th St Westside'],
		name: 'Fifth Ave & 47th St Intersection',
		label: 'Fifth Ave & 47th St Intersection',
		abbreviation: 'Fifth Ave & 47th St Intersection',
		client: 'cw',
		color: primaryColors[1],
		categories: ['Downtown'],
		owners: { 'Fifth Avenue': [] },
		startDate: '2023-10-26',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		parentOf: ['Fifth Ave & 47th St Eastside', 'Fifth Ave & 47th St Westside'],
		compare: false,
		expandLocation: true,
		defaultOpen: true,
	},
	{
		locId: '0023-5',
		qName: 'Fifth Ave & 47th St Eastside',
		name: 'Fifth Ave & 47th St Eastside',
		label: 'Fifth Ave & 47th St Eastside',
		abbreviation: 'Fifth Ave & 47th St Eastside',
		client: 'cw',
		color: primaryColors[3],
		categories: ['Downtown'],
		owners: { 'Beacon Capital Partners (575 Fifth)': [] },
		startDate: '2023-10-26',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		compare: true,
		childOf: ['Fifth Ave & 47th St Intersection'],
	},
	{
		locId: '0023-6',
		qName: 'Fifth Ave & 47th St Westside',
		name: 'Fifth Ave & 47th St Westside',
		label: 'Fifth Ave & 47th St Westside',
		abbreviation: 'Fifth Ave & 47th St Westside',
		client: 'cw',
		color: primaryColors[5],
		categories: ['Downtown'],
		owners: { 'Sae-A Trading Co. Ltd. (576 Fifth)': [] },
		startDate: '2023-10-26',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 9,
		hrEnd: 22,
		compare: true,
		childOf: ['Fifth Ave & 47th St Intersection'],
	},
	{
		locId: '0024-1',
		name: 'Fifth Ave (btw 58th & 59th) West',
		qName: 'Fifth Ave (btw 58th & 59th) West',
		label: 'Fifth Ave (btw 58th & 59th) West',
		abbreviation: 'Fifth Ave (btw 58th & 59th) West',
		client: 'faa',
		color: primaryColors[0],
		categories: ['West Side'],
		owners: { 'faa': [] },
		startDate: '2023-12-19',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0024-2',
		name: 'Fifth Ave (btw 57th & 58th) West',
		qName: 'Fifth Ave (btw 57th & 58th) West',
		label: 'Fifth Ave (btw 57th & 58th) West',
		abbreviation: 'Fifth Ave (btw 57th & 58th) West',
		client: 'faa',
		color: primaryColors[1],
		categories: ['West Side'],
		owners: { 'faa': [] },
		startDate: '2023-12-19',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0024-3',
		name: 'Fifth Ave (btw 57th & 58th) East',
		qName: 'Fifth Ave (btw 57th & 58th) East',
		label: 'Fifth Ave (btw 57th & 58th) East',
		abbreviation: 'Fifth Ave (btw 57th & 58th) East',
		client: 'faa',
		color: primaryColors[2],
		categories: ['East Side'],
		owners: { 'faa': [] },
		startDate: '2023-12-19',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0024-4',
		name: 'Fifth Ave (btw 56th & 57th) West',
		qName: 'Fifth Ave (btw 56th & 57th) West',
		label: 'Fifth Ave (btw 56th & 57th) West',
		abbreviation: 'Fifth Ave (btw 56th & 57th) West',
		client: 'faa',
		color: primaryColors[3],
		categories: ['West Side'],
		owners: { 'faa': [] },
		startDate: '2023-12-19',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0024-5',
		name: 'Fifth Ave (btw 56th & 57th) East',
		qName: 'Fifth Ave (btw 56th & 57th) East',
		label: 'Fifth Ave (btw 56th & 57th) East',
		abbreviation: 'Fifth Ave (btw 56th & 57th) East',
		client: 'faa',
		color: primaryColors[4],
		categories: ['East Side'],
		owners: { 'faa': [] },
		startDate: '2023-12-19',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0024-6',
		name: 'Fifth Ave (btw 55th & 56th) West',
		qName: 'Fifth Ave (btw 55th & 56th) West',
		label: 'Fifth Ave (btw 55th & 56th) West',
		abbreviation: 'Fifth Ave (btw 55th & 56th) West',
		client: 'faa',
		color: primaryColors[5],
		categories: ['West Side'],
		owners: { 'faa': [] },
		startDate: '2023-12-05',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0024-7',
		name: 'Fifth Ave (btw 55th & 56th) East',
		qName: 'Fifth Ave (btw 55th & 56th) East',
		label: 'Fifth Ave (btw 55th & 56th) East',
		abbreviation: 'Fifth Ave (btw 55th & 56th) East',
		client: 'faa',
		color: primaryColors[6],
		categories: ['East Side'],
		owners: { 'faa': [] },
		startDate: '2023-12-05',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	// {
	// 	locId: '0024-8',
	// 	name: 'Fifth Ave (btw 54th & 55th) West',
	// 	qName: 'Fifth Ave (btw 54th & 55th) West',
	// 	label: 'Fifth Ave (btw 54th & 55th) West',
	// 	abbreviation: 'Fifth Ave (btw 54th & 55th) West',
	// 	client: 'faa',
	// 	color: primaryColors[7],
	// 	categories: ['West Side'],
	// 	owners: { 'faa': [] },
	// 	startDate: '2023-11-24',
	// 	endDate: '',
	// 	byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
	// 	byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
	// 	hrStart: 7,
	// 	hrEnd: 22
	// },
	{
		locId: '0024-8',
		name: 'Fifth Ave (btw 54th & 55th) East',
		qName: 'Fifth Ave (btw 54th & 55th) East',
		label: 'Fifth Ave (btw 54th & 55th) East',
		abbreviation: 'Fifth Ave (btw 54th & 55th) East',
		client: 'faa',
		color: primaryColors[7],
		categories: ['East Side'],
		owners: { 'faa': [] },
		startDate: '2023-12-10',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0024-21',
		name: 'Fifth Ave (btw 53rd & 54th) West',
		qName: 'East 8th St Entering (North sidewalk)',
		label: 'Fifth Ave (btw 53rd & 54th) West',
		abbreviation: 'Fifth Ave (btw 53rd & 54th) West',
		client: 'faa',
		color: primaryColors[13],
		categories: ['West Side'],
		owners: { 'faa': [] },
		startDate: '2024-06-15',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22,
	},
	// {
	// 	locId: '0024-22',
	// 	name: 'Fifth Ave (btw 53rd & 54th) East',
	// 	qName: 'Lafayette St North Entering (East sidewalk)',
	// 	label: 'Fifth Ave (btw 53rd & 54th) East',
	// 	abbreviation: 'Fifth Ave (btw 53rd & 54th) East',
	// 	client: 'faa',
	// 	color: primaryColors[12],
	// 	categories: ['East Side'],
	// 	owners: { 'faa': [] },
	// 	startDate: '2024-06-15',
	// 	endDate: '',
	// 	byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
	// 	byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
	// 	hrStart: 7,
	// 	hrEnd: 22,
	// },
	{
		locId: '0024-18',
		name: 'Fifth Ave (btw 51st & 52nd) East',
		qName: 'Fifth Ave (btw 51st & 52nd) East',
		label: 'Fifth Ave (btw 51st & 52nd) East',
		abbreviation: 'Fifth Ave (btw 51st & 52nd) East',
		client: 'faa',
		color: primaryColors[11],
		categories: ['East Side'],
		owners: { 'faa': [] },
		startDate: '2024-03-03',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0024-16',
		name: 'Fifth Ave (btw 50th & 51st) West',
		qName: 'Fifth Ave (btw 50th & 51st) West',
		label: 'Fifth Ave (btw 50th & 51st) West',
		abbreviation: 'Fifth Ave (btw 50th & 51st) West',
		client: 'faa',
		color: primaryColors[9],
		categories: ['West Side'],
		owners: { 'faa': [] },
		startDate: '2024-03-03',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0024-17',
		name: 'Fifth Ave (btw 50th & 51st) East',
		qName: 'Fifth Ave (btw 50th & 51st) East',
		label: 'Fifth Ave (btw 50th & 51st) East',
		abbreviation: 'Fifth Ave (btw 50th & 51st) East',
		client: 'faa',
		color: primaryColors[10],
		categories: ['East Side'],
		owners: { 'faa': [] },
		startDate: '2024-03-03',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	{
		locId: '0024-9',
		name: 'Fifth Ave (btw 49th & 50th) West',
		qName: 'Fifth Ave (btw 49th & 50th) West',
		label: 'Fifth Ave (btw 49th & 50th) West',
		abbreviation: 'Fifth Ave (btw 49th & 50th) West',
		client: 'faa',
		color: primaryColors[8],
		categories: ['West Side'],
		owners: { 'faa': [] },
		startDate: '2023-12-19',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22
	},
	// {
	// 	name: 'Other Locations',
	// 	label: 'Other Locations',
	// 	abbreviation: 'Other Locations',
	// 	client: 'faa',
	// 	compare: false,
	// 	archived: false,
	// 	expandLabel: true,
	// 	parentOf: ['60th St - East of 5th (S Sidewalk)'],
	// },
	// {
	// 	locId: '0024-21',
	// 	name: '60th St - East of 5th (S Sidewalk)',
	// 	qName: '60th St - East of 5th (S Sidewalk)',
	// 	label: '60th St - East of 5th (S Sidewalk)',
	// 	abbreviation: '60th St - East of 5th (S Sidewalk)',
	// 	client: 'faa',
	// 	color: primaryColors[10],
	// 	categories: ['Downtown'],
	// 	owners: { 'faa': [] },
	// 	startDate: '2024-04-01',
	// 	endDate: '',
	// 	byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
	// 	byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
	// 	hrStart: 7,
	// 	hrEnd: 22,
	// 	childOf: ['Other Locations'],
	// },
	{
		locId: '0024-10',
		name: 'Fifth Ave (btw 58th & 59th)',
		qName: 'Fifth Ave (btw 58th & 59th)',
		label: 'Fifth Ave (btw 58th & 59th)',
		abbreviation: 'Fifth Ave (btw 58th & 59th)',
		client: 'faa',
		color: primaryColors[0],
		owners: { 'faa': [] },
		startDate: '2023-12-19',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0024-11',
		name: 'Fifth Ave (btw 57th & 58th)',
		qName: 'Fifth Ave (btw 57th & 58th)',
		label: 'Fifth Ave (btw 57th & 58th)',
		abbreviation: 'Fifth Ave (btw 57th & 58th)',
		client: 'faa',
		color: primaryColors[1],
		owners: { 'faa': [] },
		startDate: '2023-12-19',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0024-12',
		name: 'Fifth Ave (btw 56th & 57th)',
		qName: 'Fifth Ave (btw 56th & 57th)',
		label: 'Fifth Ave (btw 56th & 57th)',
		abbreviation: 'Fifth Ave (btw 56th & 57th)',
		client: 'faa',
		color: primaryColors[2],
		owners: { 'faa': [] },
		startDate: '2023-12-19',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0024-13',
		name: 'Fifth Ave (btw 55th & 56th)',
		qName: 'Fifth Ave (btw 55th & 56th)',
		label: 'Fifth Ave (btw 55th & 56th)',
		abbreviation: 'Fifth Ave (btw 55th & 56th)',
		client: 'faa',
		color: primaryColors[3],
		owners: { 'faa': [] },
		startDate: '2023-12-05',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0024-14',
		name: 'Fifth Ave (btw 54th & 55th)',
		qName: 'Fifth Ave (btw 54th & 55th)',
		label: 'Fifth Ave (btw 54th & 55th)',
		abbreviation: 'Fifth Ave (btw 54th & 55th)',
		client: 'faa',
		color: primaryColors[4],
		owners: { 'faa': [] },
		startDate: '2023-12-10',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0024-23',
		name: 'Fifth Ave (btw 53rd & 54th)',
		qName: 'Fifth Ave (btw 53rd & 54th)',
		label: 'Fifth Ave (btw 53rd & 54th)',
		abbreviation: 'Fifth Ave (btw 53rd & 54th)',
		client: 'faa',
		color: primaryColors[11],
		owners: { 'faa': [] },
		startDate: '2024-06-15',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0024-20',
		name: 'Fifth Ave (btw 51st & 52nd)',
		qName: 'Fifth Ave (btw 51st & 52nd)',
		label: 'Fifth Ave (btw 51st & 52nd)',
		abbreviation: 'Fifth Ave (btw 51st & 52nd)',
		client: 'faa',
		color: primaryColors[7],
		owners: { 'faa': [] },
		startDate: '2024-03-03',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0024-19',
		name: 'Fifth Ave (btw 50th & 51st)',
		qName: 'Fifth Ave (btw 50th & 51st)',
		label: 'Fifth Ave (btw 50th & 51st)',
		abbreviation: 'Fifth Ave (btw 50th & 51st)',
		client: 'faa',
		color: primaryColors[6],
		owners: { 'faa': [] },
		startDate: '2024-03-03',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0024-15',
		name: 'Fifth Ave (btw 49th & 50th)',
		qName: 'Fifth Ave (btw 49th & 50th)',
		label: 'Fifth Ave (btw 49th & 50th)',
		abbreviation: 'Fifth Ave (btw 49th & 50th)',
		client: 'faa',
		color: primaryColors[5],
		owners: { 'faa': [] },
		startDate: '2023-12-19',
		endDate: '',
		byTimeStart: '2019-01-01T12:00:00.000Z', // 7am
		byTimeEnd: '2019-01-02T03:00:00.000Z', // 10pm
		hrStart: 7,
		hrEnd: 22,
		compare: false,
		traffic: ['vehicle'],
		vehicles: ['bikes', 'motorbikes', 'cars', 'buses', 'trucks'],
		vDirection: ['south'],
	},
	{
		locId: '0025-7',
		name: 'Spring Street Park Total Visitors',
		qName: 'Spring Street Park Total Visitors',
		label: 'Spring Street Park Total Visitors',
		abbreviation: 'Spring St Park Total Visitors',
		client: 'hsbid',
		color: primaryColors[0],
		categories: ['Downtown'],
		owners: { 'Hudson Square BID': [] },
		startDate: '2023-09-25',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 9,
		hrEnd: 21,
		parentOf: ['Spring Street Park North Visitors', 'Spring Street Park South Visitors'],
		expandLocation: true,
		defaultOpen: true,
	},
	{
		locId: '0025-1',
		name: 'Spring Street Park North Visitors',
		qName: 'Spring Street Park North Visitors',
		label: 'Spring Street Park North Visitors',
		abbreviation: 'Spring St Park N Visitors',
		client: 'hsbid',
		color: primaryColors[1],
		categories: ['Downtown'],
		owners: { 'Hudson Square BID': [] },
		startDate: '2023-09-25',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 9,
		hrEnd: 21,
		childOf:['Spring Street Park Total Visitors']
	},
	{
		locId: '0025-2',
		name: 'Spring Street Park North Through Traffic',
		qName: 'Spring Street Park North Through Traffic',
		label: 'Spring Street Park North Through Traffic',
		abbreviation: 'Spring St Park N Through Traffic',
		client: 'hsbid',
		color: primaryColors[2],
		categories: ['Downtown'],
		owners: { 'Hudson Square BID': [] },
		startDate: '2023-09-25',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 9,
		hrEnd: 21
	},
	{
		locId: '0025-3',
		name: 'Spring Street Park North Passing',
		qName: 'Spring Street Park North Passing',
		label: 'Spring Street Park North Passing',
		abbreviation: 'Spring St Park N Passing',
		client: 'hsbid',
		color: primaryColors[3],
		categories: ['Downtown'],
		owners: { 'Hudson Square BID': [] },
		startDate: '2023-09-25',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 9,
		hrEnd: 21
	},
	{
		locId: '0025-4',
		name: 'Spring Street Park South Visitors',
		qName: 'Spring Street Park South Visitors',
		label: 'Spring Street Park South Visitors',
		abbreviation: 'Spring St Park S Visitors',
		client: 'hsbid',
		color: primaryColors[4],
		categories: ['Downtown'],
		owners: { 'Hudson Square BID': [] },
		startDate: '2023-09-25',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 9,
		hrEnd: 21,
		childOf:['Spring Street Park Total Visitors']
	},
	{
		locId: '0025-5',
		name: 'Spring Street Park South Through Traffic',
		qName: 'Spring Street Park South Through Traffic',
		label: 'Spring Street Park South Through Traffic',
		abbreviation: 'Spring St Park S Through Traffic',
		client: 'hsbid',
		color: primaryColors[5],
		categories: ['Downtown'],
		owners: { 'Hudson Square BID': [] },
		startDate: '2023-09-25',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 9,
		hrEnd: 21
	},
	{
		locId: '0025-6',
		name: 'Spring Street Park South Passing',
		qName: 'Spring Street Park South Passing',
		label: 'Spring Street Park South Passing',
		abbreviation: 'Spring St Park S Passing',
		client: 'hsbid',
		color: primaryColors[6],
		categories: ['Downtown'],
		owners: { 'Hudson Square BID': [] },
		startDate: '2023-09-25',
		endDate: '',
		byTimeStart: '2019-01-01T14:00:00.000Z', // 9am
		byTimeEnd: '2019-01-02T02:00:00.000Z', // 9pm
		hrStart: 9,
		hrEnd: 21
	},
	{
		locId: '0026-1',
		qName: [
			'East 8th St Entering (South sidewalk)', 'East 8th St Entering (North sidewalk)', 'Lafayette St North Entering (East sidewalk)',
			'Astor Pl East & Lafayette St South Entering', 'Cooper Sq South Entering (West sidewalk)',
			'Cooper Sq South Entering (East sidewalk)', 'Astor Place West Entering', 'Astor Place MTA Entering' // 'Lafayette St North Entering',
		],
		name: 'Astor Place Entering',
		label: 'Astor Place Entering',
		abbreviation: 'Astor Place Entering',
		client: 'va',
		color: primaryColors[0],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		expandLocation: true,
		defaultOpen: true,
		parentOf: [
			'Lafayette St North Entering',
			'East 8th St Entering (North sidewalk)', 'East 8th St Entering (South sidewalk)',
			'Astor Pl East & Lafayette St South Entering', 'Cooper Sq South Entering (West sidewalk)',
			'Cooper Sq South Entering (East sidewalk)', 'Astor Place East Entering', 'Astor Place MTA Entering' // 'Lafayette St North Entering (East sidewalk)',
		]
	},
	// {
	// 	locId: '0026-2',
	// 	name: 'Lafayette St North Entering (East sidewalk)',
	// 	qName: 'Lafayette St North Entering (East sidewalk)',
	// 	label: 'Lafayette St North Entering (East sidewalk)',
	// 	abbreviation: 'Lafayette St N Entering (E sidewalk)',
	// 	client: 'va',
	// 	color: primaryColors[1],
	// 	categories: ['Downtown'],
	// 	owners: { 'Village Alliance': [] },
	// 	startDate: '2024-06-01',
	// 	endDate: '',
	// 	byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
	// 	byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
	// 	hrStart: 0,
	// 	hrEnd: 24,
	// 	childOf: ['Astor Place Entering'],
	// },
	{
		locId: '0026-3',
		name: 'Lafayette St North Entering',
		qName: 'Lafayette St North Entering (East sidewalk)',
		label: 'Lafayette St North Entering',
		abbreviation: 'Lafayette St N Entering',
		client: 'va',
		color: primaryColors[2],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
		childOf: ['Astor Place Entering'],
	},
	{
		locId: '0026-4',
		name: 'East 8th St Entering (North sidewalk)',
		qName: 'East 8th St Entering (North sidewalk)',
		label: 'East 8th St Entering (North sidewalk)',
		abbreviation: 'E 8th St Entering (N sidewalk)',
		client: 'va',
		color: primaryColors[3],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
		childOf: ['Astor Place Entering'],
	},
	{
		locId: '0026-5',
		name: 'East 8th St Entering (South sidewalk)',
		qName: 'East 8th St Entering (South sidewalk)',
		label: 'East 8th St Entering (South sidewalk)',
		abbreviation: 'E 8th St Entering (S sidewalk)',
		client: 'va',
		color: primaryColors[4],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
		childOf: ['Astor Place Entering'],
	},
	{
		locId: '0026-6',
		name: 'Astor Pl East & Lafayette St South Entering',
		qName: 'Astor Pl East & Lafayette St South Entering',
		label: 'Astor Pl East & Lafayette St South Entering',
		abbreviation: 'Astor Pl & Lafayette St S Entering',
		client: 'va',
		color: primaryColors[5],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
		childOf: ['Astor Place Entering'],
	},
	{
		locId: '0026-7',
		name: 'Cooper Sq South Entering (West sidewalk)',
		qName: 'Cooper Sq South Entering (West sidewalk)',
		label: 'Cooper Sq South Entering (West sidewalk)',
		abbreviation: 'Cooper Sq South Entering (W sidewalk)',
		client: 'va',
		color: primaryColors[6],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
		childOf: ['Astor Place Entering'],
	},
	{
		locId: '0026-8',
		name: 'Cooper Sq South Entering (East sidewalk)',
		qName: 'Cooper Sq South Entering (East sidewalk)',
		label: 'Cooper Sq South Entering (East sidewalk)',
		abbreviation: 'Cooper Sq South Entering (E sidewalk)',
		client: 'va',
		color: primaryColors[7],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
		childOf: ['Astor Place Entering'],
	},
	{
		locId: '0026-9',
		name: 'Astor Place East Entering',
		qName: 'Astor Place West Entering',
		label: 'Astor Place East Entering',
		abbreviation: 'Astor Place E Entering',
		client: 'va',
		color: primaryColors[8],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
		childOf: ['Astor Place Entering'],
	},
	{
		locId: '0026-15',
		name: 'Astor Place MTA Entering',
		qName: 'Astor Place MTA Entering',
		label: 'Astor Place MTA Entering',
		abbreviation: 'Astor Place MTA Entering',
		client: 'va',
		color: secondaryColors[1],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
		childOf: ['Astor Place Entering'],
	},
	{
		locId: '0026-10',
		name: 'Astor Place East Exiting',
		qName: 'Astor Place West Exiting',
		label: 'Astor Place East Exiting',
		abbreviation: 'Astor Place E Exiting',
		client: 'va',
		color: primaryColors[9],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0026-11',
		name: 'St Marks & 3rd Ave Intersection',
		qName: 'St Marks & 3rd Ave Intersection',
		label: 'St Marks & 3rd Ave Intersection',
		abbreviation: 'St Marks & 3rd Ave Intersection',
		client: 'va',
		color: primaryColors[10],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0026-13',
		name: 'Astor Place South Plaza Entering',
		qName: 'Astor Place South Plaza Entering',
		label: 'Astor Place South Plaza Entering',
		abbreviation: 'Astor Place S Plaza Entering',
		client: 'va',
		color: primaryColors[11],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
	},
	{
		locId: '0026-14',
		name: 'Astor Place South Plaza Occupancy',
		qName: 'Astor Place South Plaza Occupancy',
		label: 'Astor Place South Plaza Occupancy',
		abbreviation: 'Astor Place S Plaza Occ',
		client: 'va',
		color: secondaryColors[0],
		categories: ['Downtown'],
		owners: { 'Village Alliance': [] },
		startDate: '2024-06-01',
		endDate: '',
		byTimeStart: '2019-01-01T05:00:00.000Z', // 12am
		byTimeEnd: '2019-01-02T05:00:00.000Z', // 12am
		hrStart: 0,
		hrEnd: 24,
		compare: false,
		occupancy: true,
	},
];

export default allLocationsArray;
