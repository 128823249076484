import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { logoutUser, changeClient } from '../../utils/services';
import { clientData } from '../../utils/clients';
import { getImage } from '../../utils/reportHelpers';
import { changePassword } from '../../actions/authActions';

import AccessControl from './AccessControl';

import plugoutLogo from './plugout.png';
import exterosLogo from './exteros_white_sm.png';
import exterosLogoMobile from './exterosLogoWhite_mobile.svg';

import {
  AppBar,
  Button,
  ClickAwayListener,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Snackbar,
  Switch,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import AssessmentIcon from '@mui/icons-material/Assessment';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';

import { styled } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  locMenu: {
    backgroundColor: '#ffffff',
    width: '100%',
  },
  mainBar: {
    backgroundColor: 'primary',
  },
  root: {
    width: '100%',
    minWidth: 150,
    maxWidth: 360,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    padding: 0
  }
});

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 28,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    marginLeft: 2,
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(28px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#7A7A7A' : '#7A7A7A',
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: 'transparent',
        '& svg': {
          color: '#7A7A7A', // Adjust color
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'transparent',
      '& svg': {
        color: '#7A7A7A', // Adjust color
      },
    },
    '&.Mui-unchecked': {
      '& .MuiSwitch-thumb': {
        '& svg': {
          color: '#7A7A7A', // Adjust color
        },
      },
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === 'dark' ? '#7A7A7A' : '#7A7A7A',
    opacity: 1,
  },
}));

const getClientLogo = (client, email) => {
  if (!client || !email) return '';

  const cObj = clientData[client?.toLowerCase() ?? '']; // gets client data obj
  const emailMatch = /@(.*)/g.exec(email?.toLowerCase() ?? '');
  const domain = (emailMatch !== null) ? emailMatch[1] : ''; // split domain from email (ex. m@exteros.com -> exteros.com)
  const clientLogo = (cObj?.logoSwap?.[domain]) ? (cObj?.logoSwap[domain]) : (cObj?.logo ?? ''); // check if user has diff logo

  return clientLogo;
};

class Navbar extends Component {
  constructor (props) {
    super(props);

    // Admin Only - create array of clients (exclude clients w/ clientListHide prop and replace client name w/ clientListName prop)
    // this.clientList = (props.auth.user?.roles?.includes('user_manager')) ? Object.keys(clientData).reduce((a, c) => (!clientData[c].clientListHide && a.push(clientData[c].clientListName || c), a), []) : [];
    this.clientList = (props.auth.user?.roles?.includes('user_manager')) ? ['faa', 'Lindt', 'DowntownAlliance', 'abs', 'bpc', 'jemb','va','dgd', 'flatiron', 'usp','jll','hsbid', 'all'] : [];

    this.clientLogo = getImage(getClientLogo(props.auth.user.client, props.auth.user.email), false, true); // check what logo user is displayed

    this.state =  {
      // User and Client Menus
      userMenu: false,
      clientMenu: false,
      menuAnchor: null,

      // Change Password
      changePassOpen: false,
      password: '',
      newPassword: '',
      confirmPassword: '',
      errors: {},
      passChangedAlert: false,

      // Access Control
      accessControlOpen: false,
    }
  }

  componentDidMount() {
    if (this.props.auth.user?.roles?.includes('user_manager') && !this.props.auth.user?.clientChanged) {
      // clientChanged prop is set as true when changeCurrentUserClient is called
      if (this.clientList.length) this.props.changeClient(this.clientList[0]);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.changePassOpen && Object.keys(nextProps.errors).length === 0) {
      this.closeChangePass();
      this.setState({ passChangedAlert: true });
    }
    else if (this.state.changePassOpen && !nextProps.errors.success) {
      this.setState({
        errors: {
          server: (nextProps.errors.message !== '') ? nextProps.errors.message : 'Something went wrong, please try again.'
        }
      });
    }
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  handleMenuOpen = (e) => {
    this.setState({
      [e.currentTarget.id]: true,
      menuAnchor: e.currentTarget
    });
  };

  handleMenuClickAway = () => {
    if (!this.state.changePassOpen) {
      this.setState({
        userMenu: false,
        clientMenu: false,
        menuAnchor: null
      });
    }
  };

  handleChangeClient = (e) => {
    e.preventDefault();
    const newClient = (e.target.innerText) ? e.target.innerText : 'all';
    this.props.changeClient(newClient);
  };

  updateVideo = (e) => {
    this.props.onUpdate(e.target.checked);
  };

  openChangePass = () => {
    this.setState({ changePassOpen: true });
  };

  closeChangePass = () => {
    this.setState({
      changePassOpen: false,
      password: '',
      newPassword: '',
      confirmPassword: '',
      errors: {}
    });
  };

  passChangedAlertClose = () => {
    this.setState({ passChangedAlert: false });
  };

  handlePasswordChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handlePasswordSubmit = (e) => {
    e.preventDefault();
    const { email } = this.props.auth.user;
    const { password, newPassword, confirmPassword } = this.state;

    if (password === '' || newPassword === '' || confirmPassword === '' || newPassword.length < 8 || newPassword !== confirmPassword) {
      let newPassErr = '';
      let confPassErr = '';

      if (newPassword === '' || newPassword.length < 8) newPassErr = (newPassword === '') ? 'Please enter a new password.' : 'Password must be at least 8 characters.';
      if (confirmPassword === '' || newPassword !== confirmPassword) confPassErr = (confirmPassword === '') ? 'Please confirm your new password.' : 'Passwords do not match.';

      return this.setState({
        errors: {
          password: (password === '') ? 'Please enter your password.' : '',
          newPassword: newPassErr,
          confirmPassword: confPassErr
        }
      });
    }

    this.setState({
      errors: {}
    });

    this.props.changePassword({ email, password, newPassword });
  };

  openaccessControl = () => {
    this.setState({ accessControlOpen: true });
  };

  closeAccessControl = () => {
    this.setState({ accessControlOpen: false });
  };

  render() {
    const { classes } = this.props;
    const { client, email, name, roles } = this.props.auth.user;
    const { accessControlOpen, changePassOpen, clientMenu, confirmPassword, errors, menuAnchor, newPassword, passChangedAlert, password, userMenu } = this.state;

    return (
      <div>
        <AppBar position='sticky' className={classes.mainBar} style={{ height: 50 }}>
          <Toolbar variant='dense' style={{ minHeight: 12 }}>
            <img alt='Logo' src={(client === 'Tavistock') ? plugoutLogo : window.innerWidth > 400 ? exterosLogo : exterosLogoMobile} style={{ height: window.innerWidth > 400 ? 17 : 28, paddingTop: window.innerWidth > 400 ? 0 : 3 }} />

            {(client === 'DowntownAlliance') &&
              <img alt='ARAlogo' src={getImage('ara.png', false, true)} style={{ display: (client === 'DowntownAlliance' && window.innerWidth > 760) ? '' : 'none', height: 25 }} />
            }

            <Grid container item xs></Grid>
            <Grid container item xs>
              {(roles?.includes('user_manager') || roles?.includes('video_streaming')) && (client === 'all' || client === 'faa') &&
                <MaterialUISwitch
                  icon={<AssessmentIcon />}
                  checkedIcon={<OndemandVideoRoundedIcon />}
                  checked={this.props.videoToggle}
                  onClick={this.updateVideo}
                />
              }
            </Grid>

            <Toolbar xs={1}>
              {!roles?.includes('user_manager') &&
                <img alt='Client Logo' src={this.clientLogo} style={{ height: 40 }} />
              }

              {/* Admin Change Client */}
              {roles?.includes('user_manager') &&
                <>
                  {client === 'all' &&
                    <Typography style={{ cursor: 'pointer' }} id='clientMenu' onClick={this.handleMenuOpen}>{'admin'}</Typography>
                  }
                  {client !== 'all' &&
                    <img alt='Client Logo' src={this.clientLogo} style={{ cursor: 'pointer', height: 40 }} id='clientMenu' onClick={this.handleMenuOpen} />
                  }

                  <Collapse in={true} unmountOnExit>
                    <Popover
                      open={clientMenu}
                      anchorEl={menuAnchor}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      <ClickAwayListener onClickAway={this.handleMenuClickAway}>
                        <List className={classes.root} aria-label='clients'>
                          {this.clientList.map((c, i) => (
                            <ListItem key={i} button onClick={this.handleChangeClient}>
                              <ListItemText primary={c} />
                            </ListItem>
                          ))}
                        </List>
                      </ClickAwayListener>
                    </Popover>
                  </Collapse>
                </>
              }

              <Collapse in={true}>
                <Button
                  variant='outlined'
                  style={{
                    textTransform: 'none',
                    minHeight: 28,
                    height: 'calc(15px + 2vh)',
                    verticalAlign: 'middle',
                    alignContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    // letterSpacing: '1.5px',
                    paddingLeft: 8,
                    paddingRight: 8,
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: 16,
                    marginRight: -15,
                    fontSize: 'calc(6px + 0.5vw)',
                    color: '#bfbfbf',
                    borderColor: '#bfbfbf',
                    borderRadius: '5px'
                  }}
                  id='userMenu'
                  onClick={this.handleMenuOpen}
                >
                  {name}
                </Button>
                <Popover
                  open={userMenu}
                  anchorEl={menuAnchor}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <ClickAwayListener onClickAway={this.handleMenuClickAway}>
                    <Paper className={classes.locMenu}>
                      <Grid container alignItems='center' justifyContent='flex-start' direction='column' style={{ padding: 15 }}>
                        <Typography style={{ color: '#00313D', marginTop: 0, marginBottom: 10, marginLeft: 0, marginRight: 0 }}>
                          {email}
                        </Typography>

                        <Button
                          fullWidth={true}
                          variant='text'
                          style={{
                            height: '36px',
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            fontSize: 12,
                            color: '#7A7A7A',
                            borderColor: '#7A7A7A',
                            borderRadius: '5px'
                          }}
                          onClick={this.openaccessControl}
                        >
                          Access Control
                        </Button>

                        {accessControlOpen &&
                          <AccessControl
                            accessControlOpen={accessControlOpen}
                            locationsObject={this.props.locationsObject}
                            onClose={this.closeAccessControl}
                          />
                        }

                        <Button
                          variant='text'
                          fullWidth={true}
                          style={{
                            height: '36px',
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            fontSize: 12,
                            color: '#7A7A7A',
                            borderColor: '#7A7A7A',
                            borderRadius: '5px'
                          }}
                          onClick={this.openChangePass}
                        >
                          Change Password
                        </Button>
                        <Dialog open={changePassOpen} onClose={this.closeChangePass}>
                          <Grid style={{ margin: 20 }}>
                            <DialogTitle>Change Password</DialogTitle>

                            <DialogContent>
                              <TextField
                                required
                                fullWidth
                                variant='filled'
                                margin='dense'
                                id='password'
                                type='password'
                                label='Current Password'
                                value={password}
                                error={errors.password ? true : false}
                                helperText={errors.password}
                                autoFocus={true}
                                className={errors.password ? 'invalid' : ''}
                                onChange={this.handlePasswordChange}
                              />
                              <Grid></Grid>
                              <TextField
                                required
                                fullWidth
                                variant='filled'
                                margin='dense'
                                id='newPassword'
                                type='password'
                                label='New Password'
                                value={newPassword}
                                error={errors.newPassword ? true : false}
                                helperText={errors.newPassword}
                                className={errors.newPassword ? 'invalid' : ''}
                                onChange={this.handlePasswordChange}
                              />
                              <TextField
                                required
                                fullWidth
                                variant='filled'
                                margin='dense'
                                id='confirmPassword'
                                type='password'
                                label='Confirm Password'
                                value={confirmPassword}
                                error={errors.confirmPassword ? true : false}
                                helperText={errors.confirmPassword}
                                className={errors.confirmPassword ? 'invalid' : ''}
                                onChange={this.handlePasswordChange}
                                onKeyDown={(ev) => {
                                  if (ev.key === 'Enter') { this.handlePasswordSubmit(ev); }
                                }}
                              />
                              <Grid>
                                <span className='red-text' style={{ color: '#f44336' }}>
                                  {errors.server}
                                </span>
                              </Grid>
                            </DialogContent>

                            <DialogActions>
                              <Grid style={{ marginRight: 15 }}>
                                <Button
                                  color='primary'
                                  style={{ paddingLeft: 20, paddingRight: 20 }}
                                  onClick={this.closeChangePass}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color='primary'
                                  style={{ paddingLeft: 20, paddingRight: 20 }}
                                  onClick={this.handlePasswordSubmit}
                                >
                                  Submit
                                </Button>
                              </Grid>
                            </DialogActions>
                          </Grid>
                        </Dialog>

                        <Button
                          variant='outlined'
                          fullWidth={true}
                          style={{
                            marginTop: 10,
                            marginBottom: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            borderRadius: '5px',
                            color: '#3A3A3A',
                            borderColor: '#3A3A3A',
                            height: '36px'
                          }}
                          onClick={this.onLogoutClick}
                        >
                          Sign Out
                        </Button>

                      </Grid>
                    </Paper>
                  </ClickAwayListener>
                </Popover>
              </Collapse>
            </Toolbar>

            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={passChangedAlert}
              transitionDuration={{ enter: 600, exit: 1000 }}
              autoHideDuration={6000}
              onClose={this.passChangedAlertClose}
              message='Password Changed Successfully'
              // action={<></>}
            />
          </Toolbar>
        </AppBar>
        <div ref='container'></div>
      </div>
    );
  }
}

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { logoutUser, changePassword, changeClient })(withStyles(styles)(Navbar));
